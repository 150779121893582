import type { Comment } from '@prisma/client'
import type { z } from 'zod'
import type { commentTypeSchema } from '~/server/schemas'

export type CommentType = z.infer<typeof commentTypeSchema>

/**
 * Configuration for loading, storing and caching comments for different entities.
 *
 * Multiple `CommentType`s can relate to the same entity.
 *
 * The `entity` usually closely related to an existing database table.
 *
 * The `commentTableRelationFieldName` is used to:
 * 1. map the `CommentType` (e.g.: `logisticsTask`)
 * 2. to the field of the `Comment`-table (e.g.: `logisticsTaskId`)
 * 3. that references the database table the comment belong to (e.g.: `LogisticsTask`)
 */
const commentConfigs: Record<CommentType, { entity: string, commentTableRelationFieldName: keyof Comment }> = {
  logisticsTask: { entity: 'logisticsTask', commentTableRelationFieldName: 'logisticsTaskId' },
  offer: { entity: 'offer', commentTableRelationFieldName: 'offerId' },
  serviceProject: { entity: 'serviceProject', commentTableRelationFieldName: 'serviceProjectId' },
  customer: { entity: 'customer', commentTableRelationFieldName: 'customerId' },
  termination: { entity: 'offer', commentTableRelationFieldName: 'offerId' },
  deliveryDate: { entity: 'logisticsTask', commentTableRelationFieldName: 'deliveryDateLogisticsId' },
  deliveryTime: { entity: 'logisticsTask', commentTableRelationFieldName: 'deliveryTimeLogisticsId' },
  invoice: { entity: 'invoice', commentTableRelationFieldName: 'invoiceId' },
  internalCostPosition: { entity: 'internalCostPosition', commentTableRelationFieldName: 'internalCostPositionId' },
  defect: { entity: 'defect', commentTableRelationFieldName: 'defectId' },
  userTask: { entity: 'task', commentTableRelationFieldName: 'userTaskId' },
  itemSet: { entity: 'itemSet', commentTableRelationFieldName: 'itemSetId' },
  machinery: { entity: 'machinery', commentTableRelationFieldName: 'machineryId' },
  specialPermission: { entity: 'specialPermission', commentTableRelationFieldName: 'specialPermissionId' },
  temporary: { entity: 'temporary', commentTableRelationFieldName: 'temporaryId' },
  storagePosition: { entity: 'externalStoragePosition', commentTableRelationFieldName: 'storagePositionId' },
  cancelledStoragePosition: { entity: 'externalStoragePosition', commentTableRelationFieldName: 'cancelledStoragePositionId' },
  verifiedStoragePosition: { entity: 'externalStoragePosition', commentTableRelationFieldName: 'verifiedStoragePositionId' },
  verifiedStoragePositionSet: { entity: 'externalStoragePositionSet', commentTableRelationFieldName: 'verifiedStoragePositionSetId' },
  offloadedStoragePositionSet: { entity: 'externalStoragePositionSet', commentTableRelationFieldName: 'offloadedStoragePositionSetId' },
  externalStorageInvoicingEvent: { entity: 'externalStorageInvoicingEvent', commentTableRelationFieldName: 'externalStorageInvoicingEventId' },
}

export default commentConfigs
