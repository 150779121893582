<script setup lang="ts">
const { t: $t } = useI18n()

import { isSameDay } from 'date-fns'
import { withQuery } from 'ufo'
import type { MachineryAccessoryInspectionDatePayload } from '../../Machinery/InspectionDateForm.vue'
import type { ApiMachineryAccessoryGetById, MachineryAccessoryCategory } from '~/types'
import { internalRoles } from '~/authorization'
import { individualCreateInnerMachineAccessorySchemas } from '~/server/schemas'

type KeyOfApiMachineryAccessoryGetById = keyof ApiMachineryAccessoryGetById

const props = defineProps<{ machineryAccessoryId: string, view?: string, isUpdateDisabled?: boolean }>()

const { machineryAccessory: queryMachineryAccessory, offerPosition: queryOfferPosition } = useQuery()
const { data: machineryAccessory, isLoading: isLoadingMachineryAccessory, error: errorMachineryAccessory } = queryMachineryAccessory.byId(ref(props.machineryAccessoryId))
const { data: compatibleAccessoryIdsData } = queryMachineryAccessory.compatibility.getCompatibleAccessoryIds(ref(props.machineryAccessoryId))

const compatibleAccessoryIds = computed(() => compatibleAccessoryIdsData.value ?? [])

const { payload: uvvPayload, open: openUVVPopup, close: closeUVVPopup } = usePopup<ApiMachineryAccessoryGetById>()

const offerPositionWhere = computed(() => ({
  machineryAccessoryId: props.machineryAccessoryId,
  isNotReturnable: true,
}))

const offerPositionEnabled = computed(() => machineryAccessory.value?.isPermanentlyNotAvailable ?? false)
const { data: notReturnableOfferPositions } = queryOfferPosition.all(offerPositionWhere, offerPositionEnabled)

const isAccessoryAttachedOrInSet = computed(() => {
  if (!machineryAccessory.value) {
    return false
  }

  return !!machineryAccessory.value.attachedMachineryId || machineryAccessory.value.itemSets.length > 0
})

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const { payload: accessoryDocumentsPayload, open: openAccessoryDocumentsPopup, close: closeAccessoryDocumentsPopup } = usePopup<any>()
const { payload: inspectionDatePayload, open: openInspectionDatePopup, close: closeInspectionDatePopup } = usePopup<MachineryAccessoryInspectionDatePayload>()
const popupConfirmDelete = ref<null | { id: string }>(null)
const { payload: compatibleAccessoriesPayload, open: openCompatibleAccessoriesPopup, close: closeCompatibleAccessoriesPopup } = usePopup<{ compatibleAccessoryIds: string[] }>()

const updateDocumentFiles = useMutation({
  mutationFn: $trpc.machineryAccessory.updateDocumentFiles.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Das Anbaugerät konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['accessories'] })
    notification.success({ title: 'Das Anbaugerät wurde erfolgreich aktualisiert', duration: 4500 })
    closeAccessoryDocumentsPopup()
  },
})

const updateInspectionDate = useMutation({
  mutationFn: $trpc.machineryAccessory.updateInspectionDate.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Das Anbaugerät konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['accessories'] })
    notification.success({ title: 'Das Anbaugerät wurde erfolgreich aktualisiert', duration: 4500 })
    closeInspectionDatePopup()
  },
})

const updateUVVData = useMutation({
  mutationFn: $trpc.machineryAccessory.updateLastUVV.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Das Anbaugerät konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['accessories'] })
    notification.success({ title: 'Das Anbaugerät wurde erfolgreich aktualisiert', duration: 4500 })
    closeUVVPopup()
  },
})

const deleteOne = useMutation({
  mutationFn: $trpc.machineryAccessory.delete.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Lagertool konnte nicht gelöscht werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['accessories'] })
    notification.success({ title: 'Die Lagertool wurde erfolgreich gelöscht', duration: 4500 })
    navigateTo('/overview/storage-tool')
  },
})

const { isRole } = useAuthorization()
const router = useRouter()
const { openAccessoryCreateOrEditPopup, openDefectCreateOrUpdatePopup, openOfferPage, openLogisticsStorageLocationPopup, openIssuanceUpdateNotReturnablePopup } = useGlobalOpeners()
const { attachedMachinery: { columns, rowProps } } = useTableColumnConfigs()
const {
  lastUVVDate: machineryAccessoryUVV,
  machineryAccessoryBaseInformation,
  machineryAccessoryPriceInformation,
  machineryAccessoryInspectionDate,
  storageLocationColumn,
} = useOneDataColumnConfigs()
const { machineryAccessoryFieldsByCategory: showMachineryAccessoryFieldsByCategory } = useFormOptionalConditions()

const dimensions: KeyOfApiMachineryAccessoryGetById[] = [
  'lengthInMillimeters',
  'widthInMillimeters',
  'heightInMillimeters',
  'transportLengthInMillimeters',
  'transportWidthInMillimeters',
  'transportHeightInMillimeters',
]
const createMachineryAccessoryKeys = [
  ...new Set(individualCreateInnerMachineAccessorySchemas.flatMap(zodObject => Object.keys(zodObject.shape))),
].filter((key): key is KeyOfApiMachineryAccessoryGetById => {
  const forbiddenSubstrings = /photo|file|document|date/i
  const isDimension = dimensions.includes(key as KeyOfApiMachineryAccessoryGetById)

  return !isDimension && !key.endsWith('At') && !forbiddenSubstrings.test(key)
})

const machineryAccessoryCreationInformation = computed(() => {
  const machineryAccessoryValue = machineryAccessory.value
  if (!machineryAccessoryValue) {
    return []
  }

  const result: OneDataColumn<ApiMachineryAccessoryGetById>[] = []
  const pushToResult = (keys: KeyOfApiMachineryAccessoryGetById[]) => {
    keys.forEach((key) => {
      const value = machineryAccessoryValue[key]
      if (!value) {
        return
      }
      if (typeof value !== 'number' && typeof value !== 'string') {
        return
      }

      result.push({
        title: $t(`privateDetailsPage.machineryAccessory.creationInformationColumn.${key}`),
        key,
        render: () => {
          if (typeof value === 'number') {
            return key === 'yearBuilt' ? value : value.toLocaleString('de-DE')
          }
          if (key === 'category') {
            return $t(`machineryAccessory.category.${value}`)
          }
          if (key === 'trailerType') {
            return $t(`machineryAccessory.trailerType.${value}`)
          }

          return value
        },
        hideIfNullish: true,
      })
    })
  }
  pushToResult(createMachineryAccessoryKeys)
  result.sort((a, b) => a.title.localeCompare(b.title, 'de-DE'))
  pushToResult(dimensions)

  return result
})

const canSeeDefects = computed(() => canEditDefects.value || isRole(['bookkeeping', 'logistics-employee']))
const canEditDefects = computed(() => isRole(['project-manager', 'department-lead', 'consignee', 'receptionist', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'driver', 'storage-lead', 'storage-employee', 'special-role-benni']))
const canSeeQRCode = computed(() => isRole(['project-manager', 'department-lead', 'consignee', 'receptionist', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'bookkeeping', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter', 'logistics-employee']))
const canSeeMachineryAccessoryInformation = computed(() => canEditMachineryAccessoryInformation.value || isRole(['storage-employee', 'logistics-employee']))
const canEditMachineryAccessoryInformation = computed(() => isRole(['project-manager', 'department-lead', 'consignee', 'receptionist', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'bookkeeping', 'storage-lead', 'special-role-benni', 'service-project-employee', 'service-project-fitter']))
const canSeeStorageLocation = computed(() => canEditStorageLocation.value || isRole(['logistics-employee']))
const canEditStorageLocation = computed(() => isRole(['project-manager', 'department-lead', 'consignee', 'receptionist', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'bookkeeping', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter', 'logistics-employee']))
const canEditCompatibleMachineryAccessories = computed(() => isRole(['department-lead', 'rental-employee', 'special-role-benni']))
const canEditUVV = computed(() => isRole(['department-lead', 'workshop-employee', 'special-role-benni']))

const isPartner = computed(() => isRole('partner-sales', false))

const isMachineryAccessorySold = computed(() => isDateBeforeOrOnToday(machineryAccessory.value?.soldAt))

const soldOfferPosition = computed(() => {
  if (!isMachineryAccessorySold.value) {
    return undefined
  }

  return machineryAccessory.value?.relatedOfferPositions.find(({ relatedOffer }) => {
    if (!machineryAccessory.value?.soldAt) {
      return false
    }
    return relatedOffer?.type === 'sale' && isSameDay(relatedOffer.obligationStartsAt, machineryAccessory.value.soldAt)
  })
})

function getOfferUrl(offerId: string) {
  return withQuery('/offer/edit', {
    'popupOffer[mode]': 'edit',
    'popupOffer[id]': offerId,
  })
}
</script>

<template>
  <ThePopup v-if="uvvPayload" :show="!!uvvPayload" title="UVV" @close="closeUVVPopup">
    <MachineryAccessoryUVVForm :payload="uvvPayload" @save="updateUVVData.mutate" />
  </ThePopup>
  <MachineryAccessoryDetailsPagePublic v-if="isPartner" :machinery-accessory-id="props.machineryAccessoryId" :for-partner="true" />
  <div v-else>
    <ThePopup v-if="accessoryDocumentsPayload" title="Dokumente hochladen" :show="!!accessoryDocumentsPayload" @close="closeAccessoryDocumentsPopup">
      <FormKit type="form" submit-label="Dokumente speichern" @submit="(payload: typeof accessoryDocumentsPayload) => machineryAccessory?.id ? updateDocumentFiles.mutate({ id: machineryAccessory.id, documentFiles: payload.documentFiles }) : null">
        <FormKitFileDropzone id="documentFiles" name="documentFiles" wrap-path-in-object label="Dokumente" :value="accessoryDocumentsPayload.data.imageFiles" />
      </FormKit>
    </ThePopup>
    <ThePopup v-if="inspectionDatePayload" title="TÜV / SP / Sondergenehmigung" show @close="closeInspectionDatePopup">
      <MachineryInspectionDateForm :payload="inspectionDatePayload" is-machinery-accessory @save-machinery-accessory="updateInspectionDate.mutate" />
    </ThePopup>

    <MachineryAccessoryCompatibleAccessoriesPopup
      v-if="compatibleAccessoriesPayload"
      :machinery-accessory-id="machineryAccessoryId"
      :current-compatible-accessory-ids="compatibleAccessoriesPayload.compatibleAccessoryIds"
      @close="closeCompatibleAccessoriesPopup"
    />

    <TheConfirmPopup v-if="popupConfirmDelete" @confirm="deleteOne.mutate(popupConfirmDelete)" @close="popupConfirmDelete = null">
      Lagertool {{ popupConfirmDelete.id }} wird gelöscht. Alle bisher gespeicherten Informationen zu diesem Lagertool gehen verloren.
    </TheConfirmPopup>

    <n-alert v-if="isMachineryAccessorySold" type="warning">
      Verkauft: Das Gerät wurde am {{ machineryAccessory?.soldAt ? useDateAsString(machineryAccessory.soldAt, 'dd.MM.yy') : 'N/A' }} an den Kunden {{ soldOfferPosition?.relatedOffer.customer.name ?? 'N/A' }} verkauft
      <NuxtLink v-if="soldOfferPosition" :to="getOfferUrl(soldOfferPosition.relatedOffer.id)" class="hover:text-primary underline">
        ({{ soldOfferPosition.relatedOffer.id }})
      </NuxtLink>
    </n-alert>
    <n-alert
      v-if="machineryAccessory?.isPermanentlyNotAvailable && notReturnableOfferPositions?.length"
      class="mb-2"
      type="warning"
    >
      Das Lagertool-Gerät ist dauerhaft nicht verfügbar ({{ machineryAccessory.notAvailableReason ?? 'N/A' }})
      <p>Basierend auf <span class="underline cursor-pointer text-primary" @click="openOfferPage({ mode: 'edit', id: notReturnableOfferPositions[0].relatedOffer.id, redirectUrl: router.currentRoute.value.fullPath })">{{ notReturnableOfferPositions[0].relatedOffer.id }}</span></p>
      <n-button
        class="!bg-white mt-2"
        @click="openIssuanceUpdateNotReturnablePopup.open({ id: notReturnableOfferPositions[0].id, shouldOpenReturnPopup: true })"
      >
        Rücknahme jetzt durchführen
      </n-button>
    </n-alert>

    <TheDataCard v-if="isRole('admin')" class="my-2">
      <n-collapse>
        <n-collapse-item>
          <template #header>
            <span class="text-lg">
              Umsatz-Übersicht
            </span>
          </template>
          <ReportingCardMachineryAccessoryRevenue :machinery-accessory-id="props.machineryAccessoryId" :from-item-set="false" />
        </n-collapse-item>
      </n-collapse>
    </TheDataCard>

    <TheDataCard v-if="isRole('admin')" class="my-2">
      <n-collapse>
        <n-collapse-item>
          <template #header>
            <span class="text-lg">
              Umsatz aus Sets
            </span>
          </template>
          <ReportingCardMachineryAccessoryRevenue :machinery-accessory-id="props.machineryAccessoryId" :from-item-set="true" />
        </n-collapse-item>
      </n-collapse>
    </TheDataCard>

    <TheDataCard v-if="isRole(internalRoles)" class="mb-2">
      <n-collapse>
        <n-collapse-item>
          <template #header>
            <span class="text-lg">
              Kalender ausklappen
            </span>
          </template>
          <CalendarPage :additional-filters-machinery-accessory="{ id: props.machineryAccessoryId }" show-calendar-for="machineryAccessories" :allow-offer-creation-mode="false" :show-machinery-accessory-filters="false" />
        </n-collapse-item>
      </n-collapse>
    </TheDataCard>
    <div class="flex gap-2 flex-wrap">
      <div class="w-full flex gap-2 flex-col DetailsColumn">
        <TheDataCard header="Geräteübersicht" :error="errorMachineryAccessory" :is-loading="isLoadingMachineryAccessory">
          <TableOneDataColumn :config="{ data: machineryAccessory, columns: machineryAccessoryBaseInformation }" />
        </TheDataCard>

        <TheDataCard
          v-if="isRole(['project-manager', 'department-lead', 'bookkeeping'])"
          :error="errorMachineryAccessory"
          :is-loading="isLoadingMachineryAccessory"
        >
          <template #header>
            <div class="CardHeader">
              <h2>Preis Informationen</h2>
              <n-button v-if="!isUpdateDisabled && isRole('bookkeeping')" @click="openAccessoryCreateOrEditPopup.open({ mode: 'update', id: machineryAccessoryId })">
                <template #icon>
                  <Icon name="material-symbols:edit-outline-rounded" />
                </template>Ändern
              </n-button>
            </div>
          </template>
          <TableOneDataColumn :config="{ data: machineryAccessory, columns: machineryAccessoryPriceInformation }" />
        </TheDataCard>

        <TheDataCard
          v-if="machineryAccessory && machineryAccessory.status !== 'creation' && canSeeQRCode"
          title="QR Code"
          :error="errorMachineryAccessory"
          :is-loading="isLoadingMachineryAccessory"
        >
          <QrCodeDownloadSelect :id="machineryAccessoryId" type="accessory" :accessory="machineryAccessory" />
        </TheDataCard>

        <TheDataCard v-if="canSeeDefects" :is-loading="isLoadingMachineryAccessory" :error="errorMachineryAccessory">
          <template #header>
            <div class="CardHeader">
              <h2>Gerätezustand / Defekte</h2>
              <n-button
                v-if="!isUpdateDisabled && machineryAccessory && canEditDefects"
                @click="openDefectCreateOrUpdatePopup.open({ itemId: machineryAccessory.id, type: 'machineryAccessory', mode: 'create' })"
              >
                <template #icon>
                  <Icon name="material-symbols:add-circle-outline" />
                </template>Erstellen
              </n-button>
            </div>
          </template>
          <n-collapse>
            <n-collapse-item title="Übersicht">
              <DefectPage v-if="machineryAccessory" :additional-filters="{ machineryAccessoryId: machineryAccessory.id }" overview-title="" />
            </n-collapse-item>
          </n-collapse>
        </TheDataCard>
        <TheDataCard v-if="machineryAccessory" :is-loading="isLoadingMachineryAccessory" :error="errorMachineryAccessory">
          <template #header>
            <div class="CardHeader">
              <h2>Dokumente</h2>
              <n-button
                v-if="!isUpdateDisabled && machineryAccessory && isRole(internalRoles)"
                @click="() => openAccessoryDocumentsPopup({ mode: 'update', data: { imageFiles: machineryAccessory?.documentFiles, selectedKey: '' } })"
              >
                <template #icon>
                  <Icon name="material-symbols:edit-outline-rounded" />
                </template>Ändern
              </n-button>
            </div>
          </template>
          <FileView :files="machineryAccessory?.documentFiles ?? []" collapsed-by-default />
        </TheDataCard>

        <TheDataCard
          v-if="canSeeMachineryAccessoryInformation && machineryAccessory?.category === 'trailer'"
          :error="errorMachineryAccessory"
          :is-loading="isLoadingMachineryAccessory"
        >
          <template #header>
            <div class="CardHeader">
              <h2>TÜV / SP / Sondergenehmigung</h2>
              <n-button
                v-if="!isUpdateDisabled && canEditMachineryAccessoryInformation"
                @click="() => openInspectionDatePopup({
                  id: machineryAccessoryId,
                  technicalInspectionDate: machineryAccessory?.technicalInspectionDate,
                  securityInspectionDate: machineryAccessory?.securityInspectionDate,
                  specialPermissions: machineryAccessory?.specialPermissions ?? [],
                })"
              >
                <template #icon>
                  <Icon name="material-symbols:edit-outline-rounded" />
                </template>Ändern
              </n-button>
            </div>
          </template>
          <span v-if="machineryAccessory.inspectionDateUpdatedAt && machineryAccessory.inspectionDateUpdatedBy">
            Zuletzt geändert von: {{ machineryAccessory.inspectionDateUpdatedBy.name }} ({{ machineryAccessory.inspectionDateUpdatedBy.email }})({{ useDateAsString(machineryAccessory.inspectionDateUpdatedAt, 'dd.MM.yyyy') }})
          </span>
          <TableOneDataColumn :config="{ data: machineryAccessory, columns: machineryAccessoryInspectionDate }" />
        </TheDataCard>
      </div>

      <div class="w-full flex gap-2 flex-col DetailsColumn">
        <TheDataCard v-if="canSeeMachineryAccessoryInformation" :is-loading="isLoadingMachineryAccessory" :error="errorMachineryAccessory">
          <template #header>
            <div class="CardHeader">
              <h2>Anlage Informationen</h2>
              <n-button v-if="!isUpdateDisabled && canEditMachineryAccessoryInformation" @click="openAccessoryCreateOrEditPopup.open({ mode: 'update', id: machineryAccessoryId })">
                <template #icon>
                  <Icon name="material-symbols:edit-outline-rounded" />
                </template>Ändern
              </n-button>
            </div>
          </template>

          <div class="flex flex-col gap-2">
            <p v-if="machineryAccessory">
              Registriert von {{ machineryAccessory.createdBy?.name ?? 'Unbekannt' }} am {{ useDateAsString(machineryAccessory.createdAt, 'dd.MM.yyyy') }}
            </p>

            <TableOneDataColumn :config="{ data: machineryAccessory, columns: machineryAccessoryCreationInformation }" />

            <FileView
              v-if="machineryAccessory && showMachineryAccessoryFieldsByCategory.vehicleRegistrationPhoto.includes(machineryAccessory.category as MachineryAccessoryCategory)"
              label="Fotos Fahrzeugschein"
              :files="machineryAccessory.vehicleRegistrationPhoto"
              collapsed-by-default
            />

            <FileView label="Fotos" :files="machineryAccessory?.photos ?? []" collapsed-by-default />
          </div>
        </TheDataCard>

        <TheDataCard :is-loading="isLoadingMachineryAccessory" :error="errorMachineryAccessory">
          <template #header>
            <div class="CardHeader">
              <h2>Kompatible Lagertools</h2>
              <n-button
                v-if="!isUpdateDisabled && canEditCompatibleMachineryAccessories" @click="openCompatibleAccessoriesPopup({ compatibleAccessoryIds })"
              >
                <template #icon>
                  <Icon name="material-symbols:edit-outline-rounded" />
                </template>Ändern
              </n-button>
            </div>
          </template>
          <MachineryAccessoryOverviewPage title="" :additional-filters="{ id: { in: compatibleAccessoryIds } }" />
        </TheDataCard>

        <TheDataCard
          v-if="canSeeStorageLocation"
          :is-loading="isLoadingMachineryAccessory"
          :error="errorMachineryAccessory"
        >
          <template #header>
            <div class="CardHeader">
              <h2>Aktueller Lagerplatz</h2>
              <n-button
                v-if="!isUpdateDisabled && !isAccessoryAttachedOrInSet && canEditStorageLocation"
                @click="openLogisticsStorageLocationPopup.open({ id: machineryAccessoryId, model: 'machineryAccessory', storageLocation: machineryAccessory?.storageLocation })"
              >
                <template #icon>
                  <Icon name="material-symbols:edit-outline-rounded" />
                </template>Ändern
              </n-button>
            </div>
          </template>
          <div v-if="machineryAccessory" class="flex flex-col pb-2">
            <span v-if="machineryAccessory.storageLocationCreatedAt && machineryAccessory.storageLocationCreatedBy">
              Initialer Lagerplatz zugeordnet von: {{ machineryAccessory.storageLocationCreatedBy.name }} ({{ machineryAccessory.storageLocationCreatedBy.email }})({{ useDateAsString(machineryAccessory.storageLocationCreatedAt, 'dd.MM.yyyy') }})
            </span>
            <span v-if="machineryAccessory.storageLocationUpdatedAt && machineryAccessory.storageLocationUpdatedBy">
              Lagerplatz zuletzt geändert von: {{ machineryAccessory.storageLocationUpdatedBy.name }} ({{ machineryAccessory.storageLocationUpdatedBy.email }})({{ useDateAsString(machineryAccessory.storageLocationUpdatedAt, 'dd.MM.yyyy') }})
            </span>
          </div>
          <TableOneDataColumn :config="{ columns: storageLocationColumn, data: machineryAccessory }" />
          <p v-if="isAccessoryAttachedOrInSet" class="mt-4">
            Lagerplatz kann nicht angepasst werden, da das Gerät aktuell {{ machineryAccessory?.attachedMachineryId ? 'fest mit einer Maschine verbunden' : 'Teil eines Set' }} ist.
          </p>
        </TheDataCard>

        <TheDataCard title="Feste Verbindung" :is-loading="isLoadingMachineryAccessory">
          <TableView
            v-if="machineryAccessory && machineryAccessory.attachedMachinery"
            :data="[machineryAccessory.attachedMachinery]"
            :columns="columns"
            :is-loading="isLoadingMachineryAccessory"
            :row-props="rowProps"
          />
          <span v-else>Dieses Gerät ist aktuell mit keiner Maschine verbunden</span>
        </TheDataCard>

        <TheDataCard v-if="canSeeMachineryAccessoryInformation" :error="errorMachineryAccessory" :is-loading="isLoadingMachineryAccessory">
          <template #header>
            <div class="CardHeader">
              <h2>UVV</h2>
              <n-button v-if="!isUpdateDisabled && canEditUVV" @click="openUVVPopup(machineryAccessory as ApiMachineryAccessoryGetById)">
                <template #icon>
                  <Icon name="material-symbols:edit-outline-rounded" />
                </template>Ändern
              </n-button>
            </div>
          </template>
          <span v-if="machineryAccessory?.lastUVVDateUpdatedAt && machineryAccessory?.lastUVVDateUpdatedBy">
            Zuletzt geändert von: {{ machineryAccessory.lastUVVDateUpdatedBy.name }} ({{ machineryAccessory.lastUVVDateUpdatedBy.email }})({{ useDateAsString(machineryAccessory.lastUVVDateUpdatedAt, 'dd.MM.yyyy') }})
          </span>
          <TableOneDataColumn :config="{ columns: machineryAccessoryUVV, data: machineryAccessory }" />
          <FileView label="Dokumente" :files="machineryAccessory?.documentUVVFiles ?? []" collapsed-by-default />
        </TheDataCard>
      </div>
    </div>
    <n-button
      v-if="isRole('admin') && machineryAccessory?.status !== 'approval'"
      color="red"
      class="mt-4"
      @click="popupConfirmDelete = { id: machineryAccessoryId }"
    >
      <Icon name="material-symbols:error-outline" class="mr-2" />
      Lagertool löschen
    </n-button>
  </div>
</template>

<style scoped>
.CardHeader {
  @apply  w-full flex justify-between mb-5
}
.CardHeader h2 {
  @apply text-lg font-semibold
}
.DetailsColumn {
  @apply w-[400px] flex-grow;
}
</style>
