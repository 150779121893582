import type { VNode } from 'vue'
import { NButton, NCollapse, NCollapseItem } from 'naive-ui'
import { useClipboard, useShare } from '@vueuse/core'
import { logisticsConditionRatingToGerman, logisticsTaskTypeToGerman, logisticsVisualDetailsPollutionToGerman, machineryAccessoryCategoryToGerman, machineryAccessoryCreationStatusToGerman, machineryDrivesToGerman, offerTypesToGerman } from '../translations'
import type { ApiInboundLogisticsTaskById, ApiInboundLogisticsTaskForDriver, ApiItemSetGetById, ApiItemSetPublicGetById, ApiLogisticsTaskGetByCuid, ApiLogisticsTaskGetById, ApiMachineryAccessoryGetById, ApiMachineryGetById, ApiOfferGetById, ApiOfferPositionGetById, ApiPublicMachineryAccessoryGetById, ApiReceptionMachineryGetById, DispatcherTaskForApp, LogisticsConditionRating, LogisticsTaskGetByCuidPositionToShip, LogisticsTaskType, LogisticsVisualDetailsPollution, MachineryAccessoryCategory, MachineryAccessoryStatus, MachineryCategories, MachineryDrives, OfferType } from '~/types'
import { NuxtLink } from '#components'

const { share, isSupported: isShareSupported } = useShare()
const { copy, isSupported: isCopySupported } = useClipboard()

export interface OneDataColumn<T> {
  title: string
  key: keyof T
  minWidth?: number
  isHeading?: boolean
  render?: (arg: T) => any
  renderComponent?: (arg: T) => VNode
  isVisible?: (arg: T) => boolean
  rowProps?: Record<string, any>
  hideIfNullish?: boolean
}

const renderBoolean = (value: boolean | undefined | null) => value === true ? 'Ja' : value === false ? 'Nein' : ''

/**
 * Find the storage location of the positions belonging to a logistics task.
 *
 * This function will return the _first_ storage location it finds for any machinery or machinery accessory.
 */
function getStorageLocationFromLogisticsTask(task: ApiLogisticsTaskGetById) {
  for (const position of task.positionsToShip) {
    const storageLocation = position.machinery?.storageLocation || position.machineryAccessory?.storageLocation
    if (storageLocation) {
      return storageLocation
    }
  }
}

const {
  machineryFieldsByMachineCategory: showMachineryFieldsByMachineCategory,
  machineryPropulsionFieldsByMachineCategory: showMachineryPropulsionFieldsByMachineCategory,
  machineryAccessoryFieldsByCategory: showMachineryAccessoryFieldsByCategory,
  machineryAccessoryPublicFieldsByCategory: showMachineryAccessoryPublicFieldsByCategory,
  logisticsMachineryDetailsFieldsByMachineCategory,
  logisticsFunctionalDetailsFieldsByMachineCategory,
  logisticsVisualDetailsFieldsByMachineCategory,
  logisticsConditionDetailsFieldsByMachineCategory,
} = useFormOptionalConditions()

const machineryPrice: OneDataColumn<ApiMachineryGetById>[] = [
  {
    title: 'EK-Preis (€)',
    key: 'pricePurchaseEuros',
    render: ({ pricePurchaseEuros }) => pricePurchaseEuros.toLocaleString('de-DE'),
  },
  {
    title: 'Zahlungsbedingung',
    key: 'paymentCondition',
  },
  {
    title: 'Zusatzkosten durch Abholung oder Lieferung (€)',
    key: 'additionalDeliveryCost',
    render: ({ additionalDeliveryCost }) => additionalDeliveryCost?.toLocaleString('de-DE'),
  },
  {
    title: 'Rückkäufe (€)',
    key: 'relatedOfferPositions',
    render: ({ relatedOfferPositions }) => relatedOfferPositions
      .filter((position): position is typeof position & { repurchasePrice: number, repurchasedAt: Date } => typeof position.repurchasePrice === 'number' && position.repurchasedAt instanceof Date)
      .sort((a, b) => a.repurchasedAt.getTime() - b.repurchasedAt.getTime())
      .map(position => `${position.repurchasePrice.toLocaleString('de-DE')} am ${useDateAsString(position.repurchasedAt, 'dd.MM.yyyy')}`)
      .join(', '),
    hideIfNullish: true,
  },
]

const machinerySalePrice: OneDataColumn<ApiMachineryGetById>[] = [
  {
    title: 'UVP (€)',
    key: 'priceRecommendedToSellFor',
    render: ({ priceRecommendedToSellFor }) => priceRecommendedToSellFor?.toLocaleString('de-DE'),
  },
  {
    title: 'Hanselmann Online VK-Preis (€)',
    key: 'priceHanselmannPubliclySellsFor',
    render: ({ priceHanselmannPubliclySellsFor }) => priceHanselmannPubliclySellsFor?.toLocaleString('de-DE'),
  },
]

const machineryRelease: OneDataColumn<ApiMachineryGetById>[] = [
  {
    title: 'Freigabe für Vermietung',
    key: 'isReleasedForRent',
    render: ({ isReleasedForRent }) => isReleasedForRent ? 'Ja' : 'Nein',
  },
  {
    title: 'Freigabe für Verkauf',
    key: 'isReleasedForSale',
    render: ({ isReleasedForSale }) => isReleasedForSale ? 'Ja' : 'Nein',
  },
]

const storageLocationColumn: OneDataColumn<{ storageLocation?: string | null }>[] = [
  {
    title: 'Lagerplatz',
    key: 'storageLocation',
    render: ({ storageLocation }) => storageLocation ?? 'N/A',
  },
]

const receptionMachineryVehicle: OneDataColumn<ApiReceptionMachineryGetById>[] = [
  {
    title: 'Baujahr',
    key: 'yearBuilt',
    hideIfNullish: true,
  },
  {
    title: 'Fahrgestell-Nr',
    key: 'chassisNumber',
    hideIfNullish: true,
  },
  {
    title: 'Eingest Serien Nr.',
    key: 'serialNumber',
    hideIfNullish: true,
  },
  {
    title: 'Fabrikat',
    key: 'product',
    hideIfNullish: true,
  },
  {
    title: 'Antriebsart',
    key: 'driveId',
    render: ({ drive }) => drive ? machineryDrivesToGerman[drive.name as MachineryDrives] : undefined,
    hideIfNullish: true,
  },
  {
    title: 'Tragkraft (kg)',
    key: 'liftingWeight',
    render: ({ liftingWeight }) => liftingWeight?.toLocaleString('de-DE'),
    hideIfNullish: true,
  },
  {
    title: 'Lastschwerpunkt',
    key: 'loadCenter',
    isVisible: ({ category }) => useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'loadCenter', category as MachineryCategories),
    hideIfNullish: true,
  },
  {
    title: 'Zustand (optisch)',
    key: 'opticalCondition',
    hideIfNullish: true,
  },
  {
    title: 'Zustand (technisch)',
    key: 'technicalCondition',
    hideIfNullish: true,
  },
  {
    title: 'Eigengewicht (kg)',
    key: 'weight',
    render: ({ weight }) => weight?.toLocaleString('de-DE'),
    hideIfNullish: true,
  },
  {
    title: 'Gesamtgewicht (kg)',
    key: 'weight',
    render: ({ weight, battery }) => ((weight ?? 0) + (battery?.weight ?? 0)).toLocaleString('de-DE'),
    isVisible: ({ weight, battery }) => !!weight && !!battery?.weight,
  },
  {
    title: 'Typ',
    key: 'type',
    render: ({ type }) => type?.name,
    hideIfNullish: true,
  },
  {
    title: 'Typ Zusatz',
    key: 'typeAddition',
  },
  {
    title: 'Betriebsstunden',
    key: 'initialOperatingHours',
    render: ({ initialOperatingHours }) => initialOperatingHours?.toLocaleString('de-DE'),
    hideIfNullish: true,
  },
  {
    title: 'Schlüssel',
    key: 'key',
    hideIfNullish: true,
  },
]

const receptionMachineryPlatform: OneDataColumn<ApiReceptionMachineryGetById>[] = [
  {
    title: 'Länge (mm)',
    key: 'platformLengthInMillimeters',
    render: ({ platformLengthInMillimeters }) => platformLengthInMillimeters?.toLocaleString('de-DE'),
    hideIfNullish: true,
  },
  {
    title: 'Breite (mm)',
    key: 'platformWidthInMillimeters',
    render: ({ platformWidthInMillimeters }) => platformWidthInMillimeters?.toLocaleString('de-DE'),
    hideIfNullish: true,
  },
  {
    title: 'Höhe (mm)',
    key: 'platformHeightInMillimeters',
    render: ({ platformHeightInMillimeters }) => platformHeightInMillimeters?.toLocaleString('de-DE'),
    hideIfNullish: true,
  },
]

const receptionMachineryMast: OneDataColumn<ApiReceptionMachineryGetById>[] = [
  {
    title: 'Mast',
    key: 'mast',
    hideIfNullish: true,
  },
  {
    title: 'Arbeitshöhe (mm)',
    key: 'workHeightInMillimeters',
    render: ({ workHeightInMillimeters }) => workHeightInMillimeters?.toLocaleString('de-DE'),
    hideIfNullish: true,
  },
  {
    title: 'Hubhöhe (mm)',
    key: 'liftingHeightInMillimeters',
    render: ({ liftingHeightInMillimeters }) => liftingHeightInMillimeters?.toLocaleString('de-DE'),
    isVisible: ({ category }) => useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'liftingHeightInMillimeters', category as MachineryCategories),
    hideIfNullish: true,
  },
  {
    title: 'Bauhöhe (mm)',
    key: 'mastOverallHeightInMillimeters',
    render: ({ mastOverallHeightInMillimeters }) => mastOverallHeightInMillimeters?.toLocaleString('de-DE'),
    isVisible: ({ category }) => useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'mastOverallHeightInMillimeters', category as MachineryCategories),
    hideIfNullish: true,
  },
  {
    title: 'Freihub (mm)',
    key: 'freeLiftInMillimeters',
    render: ({ freeLiftInMillimeters }) => freeLiftInMillimeters?.toLocaleString('de-DE'),
    isVisible: ({ category }) => useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'freeLiftInMillimeters', category as MachineryCategories),
    hideIfNullish: true,
  },
  {
    title: 'Gabelträgerbreite (mm)',
    key: 'forkCarriageWidthInMillimeters',
    render: ({ forkCarriageWidthInMillimeters }) => forkCarriageWidthInMillimeters?.toLocaleString('de-DE'),
    isVisible: ({ category }) => useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'forkCarriageWidthInMillimeters', category as MachineryCategories),
    hideIfNullish: true,
  },
  {
    title: 'Fem',
    key: 'fem',
    isVisible: ({ category }) => useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'fem', category as MachineryCategories),
    hideIfNullish: true,
  },
  {
    title: 'Hals',
    key: 'hals',
    isVisible: ({ category }) => useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'hals', category as MachineryCategories),
    hideIfNullish: true,
  },
  {
    title: 'Zusatz',
    key: 'addOns',
    isVisible: ({ category }) => useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'addOns', category as MachineryCategories),
    hideIfNullish: true,
  },
  {
    title: 'Seitliche Reichweite (mm)',
    key: 'sideReachInMillimeters',
    render: ({ sideReachInMillimeters }) => sideReachInMillimeters?.toLocaleString('de-DE'),
    isVisible: ({ category }) => useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'sideReachInMillimeters', category as MachineryCategories),
    hideIfNullish: true,
  },
]

const receptionMachineryDimension: OneDataColumn<ApiReceptionMachineryGetById>[] = [
  {
    title: 'Länge bis zum Gabelträger (mm)',
    key: 'lengthToForkfaceInMillimeters',
    isVisible: ({ category }) => useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'lengthToForkfaceInMillimeters', category as MachineryCategories),
    hideIfNullish: true,
  },
  {
    title: 'Gesamtlänge (mm)',
    key: 'totalLengthInMillimeters',
    isVisible: ({ category }) => useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'totalLengthInMillimeters', category as MachineryCategories),
    hideIfNullish: true,
  },
  {
    title: 'Radstand (mm)',
    key: 'wheelbaseInMillimeters',
    render: ({ wheelbaseInMillimeters }) => wheelbaseInMillimeters?.toLocaleString('de-DE'),
    hideIfNullish: true,
  },
  {
    title: 'Baubreite (mm)',
    key: 'overallWidthInMillimeters',
    render: ({ overallWidthInMillimeters }) => overallWidthInMillimeters?.toLocaleString('de-DE'),
    hideIfNullish: true,
  },
  {
    title: 'Bodenfreiheit Mitte',
    key: 'groundClearanceCenter',
    hideIfNullish: true,
  },
  {
    title: 'Höhe Kabine (mm)',
    key: 'cabinHeightInMillimeters',
    render: ({ cabinHeightInMillimeters }) => cabinHeightInMillimeters?.toLocaleString('de-DE'),
    isVisible: ({ category }) => useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'cabinHeightInMillimeters', category as MachineryCategories),
    hideIfNullish: true,
  },
  {
    title: 'Höhe (mm)',
    key: 'dimensionHeightInMillimeters',
    render: ({ dimensionHeightInMillimeters }) => dimensionHeightInMillimeters?.toLocaleString('de-DE'),
    isVisible: ({ category }) => useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'dimensionHeightInMillimeters', category as MachineryCategories),
    hideIfNullish: true,
  },
  {
    title: 'Bauhöhe (mm)',
    key: 'overallHeightInMillimeters',
    render: ({ overallHeightInMillimeters }) => overallHeightInMillimeters?.toLocaleString('de-DE'),
    isVisible: ({ category }) => useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'overallHeightInMillimeters', category as MachineryCategories),
    hideIfNullish: true,
  },
]

const receptionMachineryTires: OneDataColumn<ApiReceptionMachineryGetById>[] = [
  {
    title: 'Hersteller',
    key: 'tire',
    render: ({ tire }) => tire?.producerCompanyName,
    isVisible: ({ tire }) => !!tire?.producerCompanyName,
  },
  {
    title: 'Typ',
    key: 'tire',
    render: ({ tire }) => tire?.typeName,
    isVisible: ({ tire }) => !!tire?.typeName,
  },
  {
    title: 'Farbe',
    key: 'tire',
    render: ({ tire }) => tire?.color,
    isVisible: ({ tire }) => !!tire?.color,
  },
  {
    title: 'Art',
    key: 'tire',
    render: ({ tire }) => tire?.receptionMachineryTireTireTypeRelation?.receptionMachineryTireTypes?.map(type => type.typeName).join(', '),
    isVisible: ({ tire }) => !!tire?.receptionMachineryTireTireTypeRelation?.receptionMachineryTireTypes,
  },
  {
    title: 'Räder-Anzahl',
    key: 'tire',
    render: ({ tire }) => tire?.tireCount,
    isVisible: ({ tire }) => !!tire?.tireCount,
  },
  {
    title: 'Bereifung hinten',
    key: 'tire',
    isHeading: true,
    hideIfNullish: true,
  },
  {
    title: 'Größe',
    key: 'tire',
    render: ({ tire }) => tire?.rearSize,
    isVisible: ({ tire }) => !!tire?.rearSize,
  },
  {
    title: 'Anzahl',
    key: 'tire',
    render: ({ tire }) => tire?.rearCount,
    isVisible: ({ tire }) => !!tire?.rearCount,
  },
  {
    title: 'Zustand',
    key: 'tire',
    render: ({ tire }) => tire?.rearCondition,
    isVisible: ({ tire }) => !!tire?.rearCondition,
  },
  {
    title: 'Bereifung vorne',
    key: 'tire',
    isHeading: true,
    hideIfNullish: true,
  },
  {
    title: 'Größe',
    key: 'tire',
    render: ({ tire }) => tire?.frontSize,
    isVisible: ({ tire }) => !!tire?.frontSize,
  },
  {
    title: 'Anzahl',
    key: 'tire',
    render: ({ tire }) => tire?.frontCount,
    isVisible: ({ tire }) => !!tire?.frontCount,
  },
  {
    title: 'Zustand',
    key: 'tire',
    render: ({ tire }) => tire?.frontCondition,
    isVisible: ({ tire }) => !!tire?.frontCondition,
  },
]

const receptionMachineryMotor: OneDataColumn<ApiReceptionMachineryGetById>[] = [
  {
    title: 'Motor Hersteller',
    key: 'motor',
    render: ({ motor }) => motor?.producerCompanyName,
    isVisible: ({ motor, category }) => !!motor?.producerCompanyName && useShowFieldByFilters(showMachineryPropulsionFieldsByMachineCategory, 'name', category as MachineryCategories),
  },
  {
    title: 'Leistung (KW)',
    key: 'motor',
    render: ({ motor }) => motor?.power?.toLocaleString('de-DE'),
    isVisible: ({ motor }) => !!motor?.power,
  },
  {
    title: 'Typ',
    key: 'motor',
    render: ({ motor }) => motor?.typeName,
    isVisible: ({ motor, category }) => !!motor?.typeName && useShowFieldByFilters(showMachineryPropulsionFieldsByMachineCategory, 'typeName', category as MachineryCategories),
  },
  {
    title: 'Getriebe',
    key: 'motor',
    render: ({ motor }) => motor?.gear,
    isVisible: ({ motor, category }) => !!motor?.gear && useShowFieldByFilters(showMachineryPropulsionFieldsByMachineCategory, 'gear', category as MachineryCategories),
  },
  {
    title: 'Motorseriennummer',
    key: 'serialNumber',
    render: ({ motor }) => motor?.serialNumber,
    isVisible: ({ motor, category }) => !!motor?.serialNumber && useShowFieldByFilters(showMachineryPropulsionFieldsByMachineCategory, 'serialNumber', category as MachineryCategories),
  },
  {
    title: 'Getriebe Hersteller',
    key: 'motor',
    render: ({ motor }) => motor?.gearProducer,
    isVisible: ({ motor, category }) => !!motor?.gearProducer && useShowFieldByFilters(showMachineryPropulsionFieldsByMachineCategory, 'gearProducer', category as MachineryCategories),
  },
  {
    title: 'AdBlue',
    key: 'motor',
    render: ({ motor }) => motor?.hasAdBlue ? 'Ja' : 'Nein',
    isVisible: ({ motor, category }) => motor?.hasAdBlue !== null && useShowFieldByFilters(showMachineryPropulsionFieldsByMachineCategory, 'hasAdBlue', category as MachineryCategories),
  },
]

const receptionMachineryBattery: OneDataColumn<ApiReceptionMachineryGetById>[] = [
  {
    title: 'Hersteller',
    key: 'battery',
    render: ({ battery }) => battery?.producerCompanyName,
    isVisible: ({ battery }) => !!battery?.producerCompanyName,
  },
  {
    title: 'Typ',
    key: 'battery',
    render: ({ battery }) => battery?.typeName,
    isVisible: ({ battery }) => !!battery?.typeName,
  },
  {
    title: 'Gewicht (kg)',
    key: 'battery',
    render: ({ battery }) => battery?.weight?.toLocaleString('de-DE'),
    isVisible: ({ battery }) => !!battery?.weight,
  },
  {
    title: 'Volt',
    key: 'battery',
    render: ({ battery }) => battery?.volt,
    isVisible: ({ battery }) => !!battery?.volt,
  },
  {
    title: 'Baujahr',
    key: 'battery',
    render: ({ battery }) => battery?.yearBuilt,
    isVisible: ({ battery }) => !!battery?.yearBuilt,
  },
  {
    title: 'Seriennummer',
    key: 'battery',
    render: ({ battery }) => battery?.serialNumber,
    isVisible: ({ battery }) => !!battery?.serialNumber,
  },
  {
    title: 'Ampere',
    key: 'battery',
    render: ({ battery }) => battery?.ampere && `${battery.ampere}A`,
    isVisible: ({ battery }) => !!battery?.ampere,
  },
]

const receptionMachinerySteeringType: OneDataColumn<ApiReceptionMachineryGetById>[] = [
  {
    title: 'Lenkart',
    key: 'steeringType',
    hideIfNullish: true,
  },
]

const receptionMachineryExtraInfo: OneDataColumn<ApiReceptionMachineryGetById>[] = [
  {
    title: 'Zusatz',
    key: 'extraAddOns',
    hideIfNullish: true,
  },
  {
    title: 'Zubehör',
    key: 'extraAccessories',
    hideIfNullish: true,
  },
]

const machineryAccessoryBaseInformation: OneDataColumn<ApiMachineryAccessoryGetById>[] = [
  {
    title: 'ID',
    key: 'id',
  },
  {
    title: 'Status',
    key: 'status',
    render: ({ status }) => machineryAccessoryCreationStatusToGerman[status as MachineryAccessoryStatus],
  },
  {
    title: 'Set',
    key: 'itemSets',
    renderComponent: ({ itemSets }) => {
      const itemSet = itemSets[0]
      if (!itemSet) {
        return h('div', 'N/A')
      }

      const nuxtLinkComponent = h(NuxtLink, {
        class: 'hover:text-primary underline',
        to: `/set/${itemSet.id}`,
      }, () => [
        useRenderIcon({ name: useBrandIcons().set, class: 'mr-1' }),
        h('span', itemSet.title),
      ])

      return h('div', { class: 'flex gap-x-1' }, [
        nuxtLinkComponent,
        `(${itemSet.id})`,
      ])
    },
  },
]

const machineryAccessoryPriceInformation: OneDataColumn<ApiMachineryAccessoryGetById>[] = [
  {
    title: 'Preis (EUR)',
    key: 'priceInEuros',
    render: ({ priceInEuros }) => priceInEuros?.toLocaleString('de-DE'),
  },
]

const machineryAccessoryInspectionDate: OneDataColumn<ApiMachineryAccessoryGetById>[] = [
  {
    title: 'Nächste TÜV Prüfung fällig am',
    key: 'technicalInspectionDate',
    isVisible: ({ category }) => showMachineryAccessoryFieldsByCategory.technicalInspectionDate.includes(category as MachineryAccessoryCategory),
    render: ({ technicalInspectionDate }) => technicalInspectionDate ? useDateAsString(technicalInspectionDate, 'dd.MM.yyyy') : 'N/A',
  },
  {
    title: 'Nächste Sicherheitsprüfung fällig am',
    key: 'securityInspectionDate',
    isVisible: ({ category }) => showMachineryAccessoryFieldsByCategory.securityInspectionDate.includes(category as MachineryAccessoryCategory),
    render: ({ securityInspectionDate }) => securityInspectionDate ? useDateAsString(securityInspectionDate, 'dd.MM.yyyy') : 'N/A',
  },
  {
    title: 'Sondergenehmigung fällig am',
    key: 'specialPermissions',
    isVisible: ({ specialPermissions }) => specialPermissions.length > 0,
    render: ({ specialPermissions }) => specialPermissions
      .map(sp => `${sp.title} (${useDateAsString(sp.date, 'dd.MM.yyyy')})`)
      .join(', '),
  },
]

const machineryAccessoryPublicCreationInformation: OneDataColumn<ApiPublicMachineryAccessoryGetById>[] = [
  {
    title: 'Kategorie',
    key: 'category',
    render: ({ category }) => machineryAccessoryCategoryToGerman[category as MachineryAccessoryCategory],
  },
  {
    title: 'Hersteller',
    key: 'producerCompanyName',
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.producerCompanyName.includes(category as MachineryAccessoryCategory),
  },
  {
    title: 'Typ',
    key: 'typeName',
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.typeName.includes(category as MachineryAccessoryCategory),
  },
  {
    title: 'Baujahr',
    key: 'yearBuilt',
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.yearBuilt.includes(category as MachineryAccessoryCategory),
  },
  {
    title: 'Tragkraft (kg)',
    key: 'liftingWeight',
    render: ({ liftingWeight }) => liftingWeight?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.liftingWeight.includes(category as MachineryAccessoryCategory),
  },
  {
    title: 'Länge (mm)',
    key: 'lengthInMillimeters',
    render: ({ lengthInMillimeters }) => lengthInMillimeters?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.lengthInMillimeters.includes(category as MachineryAccessoryCategory),
  },
  {
    title: 'Breite (mm)',
    key: 'widthInMillimeters',
    render: ({ widthInMillimeters }) => widthInMillimeters?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.widthInMillimeters.includes(category as MachineryAccessoryCategory),
  },
  {
    title: 'Höhe (mm)',
    key: 'heightInMillimeters',
    render: ({ heightInMillimeters }) => heightInMillimeters?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.heightInMillimeters.includes(category as MachineryAccessoryCategory),
  },
  {
    title: 'IK-IK (mm)',
    key: 'distanceInnerEdgeToInnerEdge',
    render: ({ distanceInnerEdgeToInnerEdge }) => distanceInnerEdgeToInnerEdge?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.distanceInnerEdgeToInnerEdge.includes(category as MachineryAccessoryCategory),
  },
  {
    title: 'AK-AK (mm)',
    key: 'distanceOuterEdgeToOuterEdge',
    render: ({ distanceOuterEdgeToOuterEdge }) => distanceOuterEdgeToOuterEdge?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.distanceOuterEdgeToOuterEdge.includes(category as MachineryAccessoryCategory),
  },
  {
    title: 'Einfahrlaschen-Breite (mm)',
    key: 'retractionLugsWidth',
    render: ({ retractionLugsWidth }) => retractionLugsWidth?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.retractionLugsWidth.includes(category as MachineryAccessoryCategory),
  },
  {
    title: 'Einfahrlaschen-Höhe (mm)',
    key: 'retractionLugsHeight',
    render: ({ retractionLugsHeight }) => retractionLugsHeight?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.retractionLugsHeight.includes(category as MachineryAccessoryCategory),
  },
  {
    title: 'Einfahrlaschen-IK-IK (mm)',
    key: 'retractionLugsDistanceInnerEdgeToInnerEdge',
    render: ({ retractionLugsDistanceInnerEdgeToInnerEdge }) => retractionLugsDistanceInnerEdgeToInnerEdge?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.retractionLugsDistanceInnerEdgeToInnerEdge.includes(category as MachineryAccessoryCategory),
  },
  {
    title: 'Einfahrlaschen-AK-AK (mm)',
    key: 'retractionLugsDistanceOuterEdgeToOuterEdge',
    render: ({ retractionLugsDistanceOuterEdgeToOuterEdge }) => retractionLugsDistanceOuterEdgeToOuterEdge?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.retractionLugsDistanceOuterEdgeToOuterEdge.includes(category as MachineryAccessoryCategory),
  },
  {
    title: 'Länge Hebelasche (mm)',
    key: 'lengthLeverLink',
    render: ({ lengthLeverLink }) => lengthLeverLink?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.lengthLeverLink.includes(category as MachineryAccessoryCategory),
  },
  {
    title: 'Breite Hebelasche (mm)',
    key: 'widthLeverLink',
    render: ({ widthLeverLink }) => widthLeverLink?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.widthLeverLink.includes(category as MachineryAccessoryCategory),
  },
  {
    title: 'Stärke Hebelasche (mm)',
    key: 'powerLeverLink',
    render: ({ powerLeverLink }) => powerLeverLink?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.powerLeverLink.includes(category as MachineryAccessoryCategory),
  },
]

const logisticsTaskGeneralInformation: OneDataColumn<ApiLogisticsTaskGetById>[] = [
  {
    title: 'ID',
    key: 'id',
    minWidth: 200,
  },
  {
    title: 'Art',
    key: 'type',
    render: task => logisticsTaskTypeToGerman[task.type as LogisticsTaskType],
  },
  {
    title: 'Liefer-Datum (Auftrag)',
    key: 'offer',
    render: (task) => {
      if (task.type === 'outbound') {
        return task.offer?.deliveryAt ? useDateAsString(task.offer.deliveryAt) : 'N/A'
      }
      const obligationActuallyEndedAt = task.offer?.obligationActuallyEndedAt
      return obligationActuallyEndedAt ? useDateAsString(obligationActuallyEndedAt) : 'N/A'
    },
    isVisible: task => task.type !== 'inbound' && task.offer?.type !== 'special',
  },
  {
    title: 'Abhol-Datum (Auftrag)',
    key: 'offer',
    render: (task) => {
      const obligationActuallyEndedAt = task.offer?.obligationActuallyEndedAt
      return obligationActuallyEndedAt ? useDateAsString(obligationActuallyEndedAt) : 'N/A'
    },
    isVisible: task => task.type === 'inbound' && task.offer?.type !== 'special',
  },
  {
    title: 'Liefer-Datum (Logistik)',
    key: 'deliveryAt',
    render: ({ deliveryAt }) => deliveryAt ? useDateAsString(deliveryAt) : 'N/A',
    isVisible: task => task.type !== 'inbound',
  },
  {
    title: 'Abhol-Datum (Logistik)',
    key: 'deliveryAt',
    render: ({ deliveryAt }) => deliveryAt ? useDateAsString(deliveryAt) : 'N/A',
    isVisible: task => task.type === 'inbound',
  },
  {
    title: 'Typ',
    key: 'offer',
    render: ({ offer }) => offer ? offerTypesToGerman[offer.type as OfferType] : 'Unbekannt',
  },
  {
    title: 'Abholung Kunden-Kennzeichen',
    key: 'offer',
    render: ({ offer }) => offer ? offer.customerLicensePlate : 'Unbekannt',
    isVisible: task => task.doesCustomerDoTask,
  },
  {
    title: 'Lagerort',
    key: 'positionsToShip',
    render: task => getStorageLocationFromLogisticsTask(task),
    isVisible: (task) => {
      if (task.offer?.type === 'special') {
        return false
      }
      return Boolean(getStorageLocationFromLogisticsTask(task))
    },
  },
  {
    title: 'Lieferort',
    key: 'offer',
    render: (task) => {
      if (task.type === 'a-to-b') {
        return task.deliveryTo ?? 'N/A'
      }
      return task.offer?.deliveryLocation ?? 'N/A'
    },
    isVisible: task => task.type !== 'inbound',
  },
  {
    title: 'Abholort',
    key: 'offer',
    render: task => task.deliveryFrom ?? 'N/A',
    isVisible: task => task.type === 'inbound' || task.type === 'a-to-b',
  },
  {
    title: 'Abweichende Abholadresse',
    key: 'offer',
    render: task => task.differentPickupLocation ?? 'N/A',
    isVisible: ({ type }) => type === 'inbound',
  },
  {
    title: '1. Ansprechpartner',
    key: 'offer',
    render: task => task.offer?.claimingPartnerName ?? 'N/A',
  },
  {
    title: '1. Ansprechpartner Email',
    key: 'offer',
    render: task => task.offer?.claimingPartnerEmail ?? 'N/A',
  },
  {
    title: '1. Ansprechpartner Telefon',
    key: 'offer',
    render: task => task.offer?.claimingPartnerTelephone ?? 'N/A',
  },
  {
    title: '2. Ansprechpartner',
    key: 'offer',
    render: task => task.offer?.secondClaimingPartnerName,
    isVisible: task => !!task.offer?.secondClaimingPartnerName,
  },
  {
    title: '2. Ansprechpartner Email',
    key: 'offer',
    render: task => task.offer?.secondClaimingPartnerEmail || 'N/A',
    isVisible: task => !!task.offer?.secondClaimingPartnerName,
  },
  {
    title: '2. Ansprechpartner Telefon',
    key: 'offer',
    render: task => task.offer?.secondClaimingPartnerTelephone,
    isVisible: task => !!task.offer?.secondClaimingPartnerName,
  },
  {
    title: '3. Ansprechpartner',
    key: 'offer',
    render: task => task.offer?.thirdClaimingPartnerName,
    isVisible: task => !!task.offer?.thirdClaimingPartnerName,
  },
  {
    title: '3. Ansprechpartner Email',
    key: 'offer',
    render: task => task.offer?.thirdClaimingPartnerEmail || 'N/A',
    isVisible: task => !!task.offer?.thirdClaimingPartnerName,
  },
  {
    title: '3. Ansprechpartner Telefon',
    key: 'offer',
    render: task => task.offer?.thirdClaimingPartnerTelephone,
    isVisible: task => !!task.offer?.thirdClaimingPartnerName,
  },
  {
    title: 'Auftrag',
    key: 'offer',
    renderComponent: ({ offer }) => {
      return offer
        ? h('div', { class: 'flex flex-col' }, [offer.id, h(NButton, {
          size: 'tiny',
          onClick: () => window.open(`/api/pdf/offer/details/${offer.cuid}`, '_blank'),
        }, () => [useRenderIcon({ name: 'material-symbols:description-outline-rounded', class: 'mr-2' }), 'Download Auftrag'])])
        : h('div', 'N/A')
    },
  },
]

const logisticsTaskGeneralInformationForDriver: OneDataColumn<ApiLogisticsTaskGetById>[] = [
  {
    title: 'ID',
    key: 'id',
    minWidth: 200,
  },
  {
    title: 'Art',
    key: 'type',
    render: task => logisticsTaskTypeToGerman[task.type as LogisticsTaskType],
  },
  {
    title: 'Liefer-Datum (Auftrag)',
    key: 'offer',
    render: (task) => {
      if (task.type === 'outbound') {
        return task.offer?.deliveryAt ? useDateAsString(task.offer.deliveryAt) : 'N/A'
      }
      const obligationActuallyEndedAt = task.offer?.obligationActuallyEndedAt
      return obligationActuallyEndedAt ? useDateAsString(obligationActuallyEndedAt) : 'N/A'
    },
    isVisible: task => task.offer?.type !== 'special',
  },
  {
    title: 'Liefer-Datum (Logistik)',
    key: 'deliveryAt',
    render: ({ deliveryAt }) => deliveryAt ? useDateAsString(deliveryAt) : 'N/A',
  },
  {
    title: 'Typ',
    key: 'offer',
    render: ({ offer }) => offer ? offerTypesToGerman[offer.type as OfferType] : 'Unbekannt',
  },
  {
    title: 'Abholung Kunden-Kennzeichen',
    key: 'offer',
    render: ({ offer }) => offer ? offer.customerLicensePlate : 'Unbekannt',
    isVisible: task => task.doesCustomerDoTask,
  },
  {
    title: 'Lagerort',
    key: 'positionsToShip',
    render: task => getStorageLocationFromLogisticsTask(task),
    isVisible: task => Boolean(getStorageLocationFromLogisticsTask(task)),
  },
  {
    title: 'Lieferort',
    key: 'offer',
    renderComponent: (task) => {
      const deliveryLocation = task.type === 'a-to-b' ? task.deliveryTo : task.offer?.deliveryLocation
      return h('span', { class: 'font-bold text-primary' }, deliveryLocation ?? 'N/A')
    },
  },
  {
    title: 'Abholort',
    key: 'offer',
    renderComponent: task => h('span', { class: 'font-bold text-primary' }, task.deliveryFrom ?? 'N/A'),
    isVisible: task => task.type === 'a-to-b',
  },
  {
    title: 'Abweichende Abholadresse',
    key: 'offer',
    renderComponent: task => h('span', { class: 'font-bold text-primary' }, task.differentPickupLocation ?? 'N/A'),
    isVisible: ({ type }) => type === 'inbound',
  },
  {
    title: '1. Ansprechpartner Name',
    key: 'offer',
    render: task => task.offer?.claimingPartnerName ?? 'N/A',
  },
  {
    title: '1. Ansprechpartner Telefon',
    key: 'offer',
    renderComponent: task => task.offer?.claimingPartnerTelephone
      ? h('a', { class: 'underline text-primary', href: `tel:${task.offer?.claimingPartnerTelephone}` }, task.offer?.claimingPartnerTelephone)
      : h('span', 'N/A'),
  },
  {
    title: '1. Ansprechpartner E-mail',
    key: 'offer',
    renderComponent: task => task.offer?.claimingPartnerEmail
      ? h('a', { class: 'underline text-primary', href: `mailto:${task.offer?.claimingPartnerEmail}` }, task.offer?.claimingPartnerEmail)
      : h('span', 'N/A'),
  },
  {
    title: '2. Ansprechpartner Name',
    key: 'offer',
    render: task => task.offer?.secondClaimingPartnerName,
    isVisible: task => !!task.offer?.secondClaimingPartnerName,
  },
  {
    title: '2. Ansprechpartner Telefon',
    key: 'offer',
    renderComponent: task => task.offer?.secondClaimingPartnerTelephone
      ? h('a', { class: 'underline text-primary', href: `tel:${task.offer?.secondClaimingPartnerTelephone}` }, task.offer?.secondClaimingPartnerTelephone)
      : h('span', 'N/A'),
    isVisible: task => !!task.offer?.secondClaimingPartnerName,
  },
  {
    title: '2. Ansprechpartner Email',
    key: 'offer',
    renderComponent: task => task.offer?.secondClaimingPartnerEmail
      ? h('a', { class: 'underline text-primary', href: `tel:${task.offer?.secondClaimingPartnerEmail}` }, task.offer?.secondClaimingPartnerEmail)
      : h('span', 'N/A'),
    isVisible: task => !!task.offer?.secondClaimingPartnerName,
  },
  {
    title: '3. Ansprechpartner',
    key: 'offer',
    render: task => task.offer?.thirdClaimingPartnerName,
    isVisible: task => !!task.offer?.thirdClaimingPartnerName,
  },
  {
    title: '3. Ansprechpartner Telefon',
    key: 'offer',
    renderComponent: task => task.offer?.thirdClaimingPartnerTelephone
      ? h('a', { class: 'underline text-primary', href: `tel:${task.offer?.thirdClaimingPartnerTelephone}` }, task.offer?.thirdClaimingPartnerTelephone)
      : h('span', 'N/A'),
    isVisible: task => !!task.offer?.thirdClaimingPartnerName,
  },
  {
    title: '3. Ansprechpartner Email',
    key: 'offer',
    renderComponent: task => task.offer?.thirdClaimingPartnerEmail
      ? h('a', { class: 'underline text-primary', href: `tel:${task.offer?.thirdClaimingPartnerEmail}` }, task.offer?.thirdClaimingPartnerEmail)
      : h('span', 'N/A'),
    isVisible: task => !!task.offer?.thirdClaimingPartnerName,
  },
]

const getNuxtLinkComponent = (to: string, title?: string | null) => h(NuxtLink, { class: 'hover:text-primary underline', to }, () => [useRenderIcon({ name: useBrandIcons().machinery, class: 'mr-1' }), h('span', title ?? 'N/A')])

const logisticsTaskPositionsOverview: OneDataColumn<ApiLogisticsTaskGetById['positionsToShip']>[] = [
  {
    title: 'Maschine',
    key: 'toString',
    renderComponent: (positionsToShip) => {
      const position = positionsToShip.find(position => position.type === 'machinery')
      const positionWeight = position?.type === 'machinery' ? position?.machinery?.receptionMachinery?.weight : position?.machineryAccessory?.weightInKilograms

      return position
        ? h('div', { class: 'flex gap-x-1' }, [
          getNuxtLinkComponent(`/machinery/${position.machineryId}`, position.title),
          positionWeight && [`(${positionWeight?.toLocaleString('de-DE')}kg)`],
        ])
        : h('div', 'N/A')
    },
    isVisible: positionsToShip => positionsToShip.some(position => position.type === 'machinery'),
  },
  {
    title: 'Anbaugeräte',
    key: 'toString',
    renderComponent: (positionsToShip) => {
      const positions = positionsToShip.filter(position => position.type === 'machineryAccessory')
      return positions
        ? h('div', { class: 'flex flex-col' }, positions.map(({ machineryAccessoryId, machineryAccessory }) => {
          const accessoryWeight = machineryAccessory?.weightInKilograms
          return h('div', { class: 'flex gap-x-1' }, [
            getNuxtLinkComponent(`/machinery-accessory/${machineryAccessoryId}`, machineryAccessoryId),
            accessoryWeight && [`(${accessoryWeight?.toLocaleString('de-DE')}kg)`],
          ])
        },
        ))
        : h('div', 'N/A')
    },
    isVisible: positionsToShip => positionsToShip.some(position => position.type === 'machineryAccessory'),
  },
  {
    title: 'Sets',
    key: 'toString',
    renderComponent: (positionsToShip) => {
      const positions = positionsToShip.filter(position => position.type === 'itemSet')
      return positions
        ? h('div', { class: 'flex flex-col' }, positions.map(({ itemSetId, itemSetMember }) => {
          return h('div', { class: 'flex gap-x-1 flex-col' }, [
            getNuxtLinkComponent(`/set/${itemSetId}`, itemSetId),
            h(NCollapse, { class: 'mt-1 mb-2 ml-2' }, () => h(NCollapseItem, { title: 'Anbaugeräte' }, () => h('div', { class: 'flex gap-2' }, itemSetMember.map(({ machineryAccessoryId }) => getNuxtLinkComponent(`/machinery-accessory/${machineryAccessoryId}`, machineryAccessoryId))))),
          ])
        }))
        : h('div', 'N/A')
    },
    isVisible: positionsToShip => positionsToShip.some(position => position.type === 'itemSet'),
  },
]

type LogisticsTaskItemSetMemberPosition = Exclude<ApiLogisticsTaskGetByCuid['positionsToShip'][number]['itemSetMember'][number], null>

const logisticsIssuanceAndMachineryDetails: OneDataColumn<LogisticsTaskGetByCuidPositionToShip>[] = [
  {
    title: 'Geräte Nr.',
    key: 'id',
    minWidth: 200,
    render: ({ machinery }) => machinery?.id,
  },
  {
    title: 'Gerätedaten',
    key: 'id',
    isHeading: true,
  },
  {
    title: 'Tankfüllung (Liter)',
    key: 'outboundLogisticsMachineryDetails',
    render: ({ outboundLogisticsMachineryDetails }) => outboundLogisticsMachineryDetails?.tankFilling?.toLocaleString('de-DE'),
    isVisible: ({ machinery, outboundLogisticsMachineryDetails }) => machinery
      ? Boolean(outboundLogisticsMachineryDetails?.tankFilling) && useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'tankFilling', machinery?.category as MachineryCategories, machinery?.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'AdBlue (Liter)',
    key: 'outboundLogisticsMachineryDetails',
    render: ({ outboundLogisticsMachineryDetails }) => outboundLogisticsMachineryDetails?.adblueFilling?.toLocaleString('de-DE'),
    isVisible: ({ machinery, outboundLogisticsMachineryDetails }) => machinery
      ? Boolean(outboundLogisticsMachineryDetails?.adblueFilling) && useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'adblueFilling', machinery?.category as MachineryCategories, machinery?.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Tank ist voll',
    key: 'outboundLogisticsMachineryDetails',
    render: ({ outboundLogisticsMachineryDetails }) => renderBoolean(outboundLogisticsMachineryDetails?.isTankFull),
    isVisible: ({ machinery, outboundLogisticsMachineryDetails }) => machinery
      ? typeof outboundLogisticsMachineryDetails?.isTankFull === 'boolean' && useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'isTankFull', machinery?.category as MachineryCategories, machinery?.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Betriebsstunden Zähler (Stunden)',
    key: 'outboundLogisticsMachineryDetails',
    render: ({ outboundLogisticsMachineryDetails }) => outboundLogisticsMachineryDetails?.operatingHours?.toLocaleString('de-DE'),
    isVisible: ({ machinery, outboundLogisticsMachineryDetails }) => machinery
      ? Boolean(outboundLogisticsMachineryDetails?.operatingHours) && useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'operatingHours', machinery?.category as MachineryCategories, machinery?.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Zündschlüssel (Anzahl)',
    key: 'outboundLogisticsMachineryDetails',
    render: ({ outboundLogisticsMachineryDetails }) => outboundLogisticsMachineryDetails?.ignitionKeys?.toLocaleString('de-DE'),
    isVisible: ({ machinery, outboundLogisticsMachineryDetails }) => machinery
      ? Boolean(outboundLogisticsMachineryDetails?.ignitionKeys) && useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'ignitionKeys', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Gasflaschen (insgesamt)',
    key: 'outboundLogisticsMachineryDetails',
    render: ({ outboundLogisticsMachineryDetails }) => outboundLogisticsMachineryDetails?.gasTanks?.toLocaleString('de-DE'),
    isVisible: ({ machinery, outboundLogisticsMachineryDetails }) => machinery
      ? Boolean(outboundLogisticsMachineryDetails?.gasTanks) && useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'gasTanks', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'TÜV',
    key: 'outboundLogisticsMachineryDetails',
    render: ({ outboundLogisticsMachineryDetails }) => {
      const date = outboundLogisticsMachineryDetails?.tuvValiditedAt
      return date ? useDateAsString(date) : 'N/A'
    },
    isVisible: ({ machinery, outboundLogisticsMachineryDetails }) => machinery
      ? Boolean(outboundLogisticsMachineryDetails?.tuvValiditedAt) && useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'tuvValiditedAt', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'SP',
    key: 'outboundLogisticsMachineryDetails',
    render: ({ outboundLogisticsMachineryDetails }) => {
      const date = outboundLogisticsMachineryDetails?.securityCheckValiditedAt
      return date ? useDateAsString(date) : 'N/A'
    },
    isVisible: ({ machinery, outboundLogisticsMachineryDetails }) => machinery
      ? Boolean(outboundLogisticsMachineryDetails?.securityCheckValiditedAt) && useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'securityCheckValiditedAt', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Funktionsprüfung',
    key: 'outboundLogisticsFunctionalDetails',
    isHeading: true,
  },
  {
    title: 'Motor, Getriebe',
    key: 'outboundLogisticsFunctionalDetails',
    render: ({ outboundLogisticsFunctionalDetails }) => renderBoolean(outboundLogisticsFunctionalDetails?.areEngineAndGearboxWorking),
    isVisible: ({ machinery, outboundLogisticsFunctionalDetails }) => machinery
      ? typeof outboundLogisticsFunctionalDetails?.areEngineAndGearboxWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'areEngineAndGearboxWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Bremsen',
    key: 'outboundLogisticsFunctionalDetails',
    render: ({ outboundLogisticsFunctionalDetails }) => renderBoolean(outboundLogisticsFunctionalDetails?.isBreakWorking),
    isVisible: ({ machinery, outboundLogisticsFunctionalDetails }) => machinery
      ? typeof outboundLogisticsFunctionalDetails?.isBreakWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isBreakWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Lenkung',
    key: 'outboundLogisticsFunctionalDetails',
    render: ({ outboundLogisticsFunctionalDetails }) => renderBoolean(outboundLogisticsFunctionalDetails?.isSteerwheelWorking),
    isVisible: ({ machinery, outboundLogisticsFunctionalDetails }) => machinery
      ? typeof outboundLogisticsFunctionalDetails?.isSteerwheelWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isSteerwheelWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Hubgerüst, Kette, Lager',
    key: 'outboundLogisticsFunctionalDetails',
    render: ({ outboundLogisticsFunctionalDetails }) => renderBoolean(outboundLogisticsFunctionalDetails?.areMastAndChainAndBearingWorking),
    isVisible: ({ machinery, outboundLogisticsFunctionalDetails }) => machinery
      ? typeof outboundLogisticsFunctionalDetails?.areMastAndChainAndBearingWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'areMastAndChainAndBearingWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Elektrische Anlage',
    key: 'outboundLogisticsFunctionalDetails',
    render: ({ outboundLogisticsFunctionalDetails }) => renderBoolean(outboundLogisticsFunctionalDetails?.isElectricalSystemWorking),
    isVisible: ({ machinery, outboundLogisticsFunctionalDetails }) => machinery
      ? typeof outboundLogisticsFunctionalDetails?.isElectricalSystemWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isElectricalSystemWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Anbaugeräte',
    key: 'outboundLogisticsFunctionalDetails',
    render: ({ outboundLogisticsFunctionalDetails }) => renderBoolean(outboundLogisticsFunctionalDetails?.isMachineryAccessory),
    isVisible: ({ machinery, outboundLogisticsFunctionalDetails }) => machinery
      ? typeof outboundLogisticsFunctionalDetails?.isMachineryAccessory === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isMachineryAccessory', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Sicherheitseinrichtung',
    key: 'outboundLogisticsFunctionalDetails',
    render: ({ outboundLogisticsFunctionalDetails }) => renderBoolean(outboundLogisticsFunctionalDetails?.isSecurityEquipmentWorking),
    isVisible: ({ machinery, outboundLogisticsFunctionalDetails }) => machinery
      ? typeof outboundLogisticsFunctionalDetails?.isSecurityEquipmentWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isSecurityEquipmentWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Beleuchtung',
    key: 'outboundLogisticsFunctionalDetails',
    render: ({ outboundLogisticsFunctionalDetails }) => renderBoolean(outboundLogisticsFunctionalDetails?.isLightingWorking),
    isVisible: ({ machinery, outboundLogisticsFunctionalDetails }) => machinery
      ? typeof outboundLogisticsFunctionalDetails?.isLightingWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isLightingWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Batterie',
    key: 'outboundLogisticsFunctionalDetails',
    render: ({ outboundLogisticsFunctionalDetails }) => renderBoolean(outboundLogisticsFunctionalDetails?.isBatteryWorking),
    isVisible: ({ machinery, outboundLogisticsFunctionalDetails }) => machinery
      ? typeof outboundLogisticsFunctionalDetails?.isBatteryWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isBatteryWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Warnlicht, Hupe',
    key: 'outboundLogisticsFunctionalDetails',
    render: ({ outboundLogisticsFunctionalDetails }) => renderBoolean(outboundLogisticsFunctionalDetails?.areWarningLightAndHornWorking),
    isVisible: ({ machinery, outboundLogisticsFunctionalDetails }) => machinery
      ? typeof outboundLogisticsFunctionalDetails?.areWarningLightAndHornWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'areWarningLightAndHornWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Sicherheitsabschaltung',
    key: 'outboundLogisticsFunctionalDetails',
    render: ({ outboundLogisticsFunctionalDetails }) => renderBoolean(outboundLogisticsFunctionalDetails?.isSafetyShutdownWorking),
    isVisible: ({ machinery, outboundLogisticsFunctionalDetails }) => machinery
      ? typeof outboundLogisticsFunctionalDetails?.isSafetyShutdownWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isSafetyShutdownWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Hydrauliksystem',
    key: 'outboundLogisticsFunctionalDetails',
    render: ({ outboundLogisticsFunctionalDetails }) => renderBoolean(outboundLogisticsFunctionalDetails?.isHydraulicSystemWorking),
    isVisible: ({ machinery, outboundLogisticsFunctionalDetails }) => machinery
      ? typeof outboundLogisticsFunctionalDetails?.isHydraulicSystemWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isHydraulicSystemWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'GPS in Ordnung',
    key: 'outboundLogisticsFunctionalDetails',
    render: ({ outboundLogisticsFunctionalDetails }) => renderBoolean(outboundLogisticsFunctionalDetails?.isGpsWorking),
    isVisible: ({ machinery, outboundLogisticsFunctionalDetails }) => machinery
      ? typeof outboundLogisticsFunctionalDetails?.isGpsWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isGpsWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Bemerkung',
    key: 'outboundLogisticsFunctionalDetails',
    render: ({ outboundLogisticsFunctionalDetails }) => outboundLogisticsFunctionalDetails?.note,
    isVisible: ({ machinery, outboundLogisticsFunctionalDetails }) => machinery
      ? Boolean(outboundLogisticsFunctionalDetails?.note) && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'note', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Sichtprüfung',
    key: 'outboundLogisticsVisualDetails',
    isHeading: true,
  },
  {
    title: 'Gerät komplett',
    key: 'outboundLogisticsVisualDetails',
    render: ({ outboundLogisticsVisualDetails }) => renderBoolean(outboundLogisticsVisualDetails?.isMachineryComplete),
    isVisible: ({ machinery, outboundLogisticsVisualDetails }) => machinery
      ? typeof outboundLogisticsVisualDetails?.isMachineryComplete === 'boolean' && useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'isMachineryComplete', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Äußere Schäden',
    key: 'outboundLogisticsVisualDetails',
    render: ({ outboundLogisticsVisualDetails }) => renderBoolean(outboundLogisticsVisualDetails?.hasExternalDamage),
    isVisible: ({ machinery, outboundLogisticsVisualDetails }) => machinery
      ? typeof outboundLogisticsVisualDetails?.hasExternalDamage === 'boolean' && useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'hasExternalDamage', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Ölstand OK',
    key: 'outboundLogisticsVisualDetails',
    render: ({ outboundLogisticsVisualDetails }) => renderBoolean(outboundLogisticsVisualDetails?.isOilLevelOk),
    isVisible: ({ machinery, outboundLogisticsVisualDetails }) => machinery
      ? typeof outboundLogisticsVisualDetails?.isOilLevelOk === 'boolean' && useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'isOilLevelOk', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Kühlwasserstand OK',
    key: 'outboundLogisticsVisualDetails',
    render: ({ outboundLogisticsVisualDetails }) => renderBoolean(outboundLogisticsVisualDetails?.isCoolantLevelOk),
    isVisible: ({ machinery, outboundLogisticsVisualDetails }) => machinery
      ? typeof outboundLogisticsVisualDetails?.isCoolantLevelOk === 'boolean' && useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'isCoolantLevelOk', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Leckagen',
    key: 'outboundLogisticsVisualDetails',
    render: ({ outboundLogisticsVisualDetails }) => renderBoolean(outboundLogisticsVisualDetails?.isLeaking),
    isVisible: ({ machinery, outboundLogisticsVisualDetails }) => machinery
      ? typeof outboundLogisticsVisualDetails?.isLeaking === 'boolean' && useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'isLeaking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Gerät gereinigt',
    key: 'outboundLogisticsVisualDetails',
    render: ({ outboundLogisticsVisualDetails }) => renderBoolean(outboundLogisticsVisualDetails?.isMachineryCleaned),
    isVisible: ({ machinery, outboundLogisticsVisualDetails }) => machinery
      ? typeof outboundLogisticsVisualDetails?.isMachineryCleaned === 'boolean' && useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'isMachineryCleaned', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Verschmutzung',
    key: 'outboundLogisticsVisualDetails',
    render: ({ outboundLogisticsVisualDetails }) => logisticsVisualDetailsPollutionToGerman[outboundLogisticsVisualDetails?.pollution as LogisticsVisualDetailsPollution],
    isVisible: ({ machinery, outboundLogisticsVisualDetails }) => machinery
      ? Boolean(outboundLogisticsVisualDetails?.pollution) && useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'pollution', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Masseband vorhanden',
    key: 'outboundLogisticsVisualDetails',
    render: ({ outboundLogisticsVisualDetails }) => renderBoolean(outboundLogisticsVisualDetails?.isTapeMeasureAvailable),
    isVisible: ({ machinery, outboundLogisticsVisualDetails }) => machinery
      ? typeof outboundLogisticsVisualDetails?.isTapeMeasureAvailable === 'boolean' && useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'isTapeMeasureAvailable', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Bemerkung',
    key: 'outboundLogisticsVisualDetails',
    render: ({ outboundLogisticsVisualDetails }) => outboundLogisticsVisualDetails?.note,
    isVisible: ({ machinery, outboundLogisticsVisualDetails }) => machinery
      ? Boolean(outboundLogisticsVisualDetails?.note) && useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'note', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Zustand',
    key: 'outboundLogisticsConditionDetails',
    isHeading: true,
  },
  {
    title: 'Reifen Luft / SE',
    key: 'outboundLogisticsConditionDetails',
    render: ({ outboundLogisticsConditionDetails }) => logisticsConditionRatingToGerman[outboundLogisticsConditionDetails?.tiresAirAndSE as LogisticsConditionRating],
    isVisible: ({ machinery, outboundLogisticsConditionDetails }) => machinery
      ? Boolean(outboundLogisticsConditionDetails?.tiresAirAndSE) && useShowFieldByFilters(logisticsConditionDetailsFieldsByMachineCategory, 'tiresAirAndSE', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Gabeln / Schaufeln',
    key: 'outboundLogisticsConditionDetails',
    render: ({ outboundLogisticsConditionDetails }) => logisticsConditionRatingToGerman[outboundLogisticsConditionDetails?.forkesAndShovels as LogisticsConditionRating],
    isVisible: ({ machinery, outboundLogisticsConditionDetails }) => machinery
      ? Boolean(outboundLogisticsConditionDetails?.forkesAndShovels) && useShowFieldByFilters(logisticsConditionDetailsFieldsByMachineCategory, 'forkesAndShovels', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Lackierung',
    key: 'outboundLogisticsConditionDetails',
    render: ({ outboundLogisticsConditionDetails }) => logisticsConditionRatingToGerman[outboundLogisticsConditionDetails?.painting as LogisticsConditionRating],
    isVisible: ({ machinery, outboundLogisticsConditionDetails }) => machinery
      ? Boolean(outboundLogisticsConditionDetails?.painting) && useShowFieldByFilters(logisticsConditionDetailsFieldsByMachineCategory, 'painting', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Fahrersitz',
    key: 'outboundLogisticsConditionDetails',
    render: ({ outboundLogisticsConditionDetails }) => logisticsConditionRatingToGerman[outboundLogisticsConditionDetails?.driverSeat as LogisticsConditionRating],
    isVisible: ({ machinery, outboundLogisticsConditionDetails }) => machinery
      ? Boolean(outboundLogisticsConditionDetails?.driverSeat) && useShowFieldByFilters(logisticsConditionDetailsFieldsByMachineCategory, 'driverSeat', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Bemerkung',
    key: 'outboundLogisticsConditionDetails',
    render: ({ outboundLogisticsConditionDetails }) => outboundLogisticsConditionDetails?.note,
    isVisible: ({ machinery, outboundLogisticsConditionDetails }) => machinery
      ? Boolean(outboundLogisticsConditionDetails?.note) && useShowFieldByFilters(logisticsConditionDetailsFieldsByMachineCategory, 'note', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
]

const logisticsReturnAndMachineryDetails: OneDataColumn<LogisticsTaskGetByCuidPositionToShip>[] = [
  {
    title: 'Geräte Nr.',
    key: 'id',
    minWidth: 200,
    render: ({ machinery }) => machinery?.id,
  },
  {
    title: 'Gerätedaten',
    key: 'id',
    isHeading: true,
  },
  {
    title: 'Tankfüllung (Liter)',
    key: 'inboundLogisticsMachineryDetails',
    render: ({ inboundLogisticsMachineryDetails }) => inboundLogisticsMachineryDetails?.tankFilling?.toLocaleString('de-DE'),
    isVisible: ({ machinery, inboundLogisticsMachineryDetails }) => machinery
      ? Boolean(inboundLogisticsMachineryDetails?.tankFilling) && useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'tankFilling', machinery?.category as MachineryCategories, machinery?.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'AdBlue (Liter)',
    key: 'inboundLogisticsMachineryDetails',
    render: ({ inboundLogisticsMachineryDetails }) => inboundLogisticsMachineryDetails?.adblueFilling?.toLocaleString('de-DE'),
    isVisible: ({ machinery, inboundLogisticsMachineryDetails }) => machinery
      ? Boolean(inboundLogisticsMachineryDetails?.adblueFilling) && useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'adblueFilling', machinery?.category as MachineryCategories, machinery?.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Tank ist voll',
    key: 'inboundLogisticsMachineryDetails',
    render: ({ inboundLogisticsMachineryDetails }) => renderBoolean(inboundLogisticsMachineryDetails?.isTankFull),
    isVisible: ({ machinery, inboundLogisticsMachineryDetails }) => machinery
      ? typeof inboundLogisticsMachineryDetails?.isTankFull === 'boolean' && useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'isTankFull', machinery?.category as MachineryCategories, machinery?.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Betriebsstunden Zähler (Stunden)',
    key: 'inboundLogisticsMachineryDetails',
    render: ({ inboundLogisticsMachineryDetails }) => inboundLogisticsMachineryDetails?.operatingHours?.toLocaleString('de-DE'),
    isVisible: ({ machinery, inboundLogisticsMachineryDetails }) => machinery
      ? Boolean(inboundLogisticsMachineryDetails?.operatingHours) && useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'operatingHours', machinery?.category as MachineryCategories, machinery?.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Zündschlüssel (Anzahl)',
    key: 'inboundLogisticsMachineryDetails',
    render: ({ inboundLogisticsMachineryDetails }) => inboundLogisticsMachineryDetails?.ignitionKeys?.toLocaleString('de-DE'),
    isVisible: ({ machinery, inboundLogisticsMachineryDetails }) => machinery
      ? Boolean(inboundLogisticsMachineryDetails?.ignitionKeys) && useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'ignitionKeys', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Gasflaschen (insgesamt)',
    key: 'inboundLogisticsMachineryDetails',
    render: ({ inboundLogisticsMachineryDetails }) => inboundLogisticsMachineryDetails?.gasTanks?.toLocaleString('de-DE'),
    isVisible: ({ machinery, inboundLogisticsMachineryDetails }) => machinery
      ? Boolean(inboundLogisticsMachineryDetails?.gasTanks) && useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'gasTanks', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'TÜV',
    key: 'inboundLogisticsMachineryDetails',
    render: ({ inboundLogisticsMachineryDetails }) => {
      const date = inboundLogisticsMachineryDetails?.tuvValiditedAt
      return date ? useDateAsString(date) : 'N/A'
    },
    isVisible: ({ machinery, inboundLogisticsMachineryDetails }) => machinery
      ? Boolean(inboundLogisticsMachineryDetails?.tuvValiditedAt) && useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'tuvValiditedAt', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'SP',
    key: 'inboundLogisticsMachineryDetails',
    render: ({ inboundLogisticsMachineryDetails }) => {
      const date = inboundLogisticsMachineryDetails?.securityCheckValiditedAt
      return date ? useDateAsString(date) : 'N/A'
    },
    isVisible: ({ machinery, inboundLogisticsMachineryDetails }) => machinery
      ? Boolean(inboundLogisticsMachineryDetails?.securityCheckValiditedAt) && useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'securityCheckValiditedAt', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Funktionsprüfung',
    key: 'inboundLogisticsFunctionalDetails',
    isHeading: true,
  },
  {
    title: 'Motor, Getriebe',
    key: 'inboundLogisticsFunctionalDetails',
    render: ({ inboundLogisticsFunctionalDetails }) => renderBoolean(inboundLogisticsFunctionalDetails?.areEngineAndGearboxWorking),
    isVisible: ({ machinery, inboundLogisticsFunctionalDetails }) => machinery
      ? typeof inboundLogisticsFunctionalDetails?.areEngineAndGearboxWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'areEngineAndGearboxWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Bremsen',
    key: 'inboundLogisticsFunctionalDetails',
    render: ({ inboundLogisticsFunctionalDetails }) => renderBoolean(inboundLogisticsFunctionalDetails?.isBreakWorking),
    isVisible: ({ machinery, inboundLogisticsFunctionalDetails }) => machinery
      ? typeof inboundLogisticsFunctionalDetails?.isBreakWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isBreakWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Lenkung',
    key: 'inboundLogisticsFunctionalDetails',
    render: ({ inboundLogisticsFunctionalDetails }) => renderBoolean(inboundLogisticsFunctionalDetails?.isSteerwheelWorking),
    isVisible: ({ machinery, inboundLogisticsFunctionalDetails }) => machinery
      ? typeof inboundLogisticsFunctionalDetails?.isSteerwheelWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isSteerwheelWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Hubgerüst, Kette, Lager',
    key: 'inboundLogisticsFunctionalDetails',
    render: ({ inboundLogisticsFunctionalDetails }) => renderBoolean(inboundLogisticsFunctionalDetails?.areMastAndChainAndBearingWorking),
    isVisible: ({ machinery, inboundLogisticsFunctionalDetails }) => machinery
      ? typeof inboundLogisticsFunctionalDetails?.areMastAndChainAndBearingWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'areMastAndChainAndBearingWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Elektrische Anlage',
    key: 'inboundLogisticsFunctionalDetails',
    render: ({ inboundLogisticsFunctionalDetails }) => renderBoolean(inboundLogisticsFunctionalDetails?.isElectricalSystemWorking),
    isVisible: ({ machinery, inboundLogisticsFunctionalDetails }) => machinery
      ? typeof inboundLogisticsFunctionalDetails?.isElectricalSystemWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isElectricalSystemWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Anbaugeräte',
    key: 'inboundLogisticsFunctionalDetails',
    render: ({ inboundLogisticsFunctionalDetails }) => renderBoolean(inboundLogisticsFunctionalDetails?.isMachineryAccessory),
    isVisible: ({ machinery, inboundLogisticsFunctionalDetails }) => machinery
      ? typeof inboundLogisticsFunctionalDetails?.isMachineryAccessory === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isMachineryAccessory', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Sicherheitseinrichtung',
    key: 'inboundLogisticsFunctionalDetails',
    render: ({ inboundLogisticsFunctionalDetails }) => renderBoolean(inboundLogisticsFunctionalDetails?.isSecurityEquipmentWorking),
    isVisible: ({ machinery, inboundLogisticsFunctionalDetails }) => machinery
      ? typeof inboundLogisticsFunctionalDetails?.isSecurityEquipmentWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isSecurityEquipmentWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Beleuchtung',
    key: 'inboundLogisticsFunctionalDetails',
    render: ({ inboundLogisticsFunctionalDetails }) => renderBoolean(inboundLogisticsFunctionalDetails?.isLightingWorking),
    isVisible: ({ machinery, inboundLogisticsFunctionalDetails }) => machinery
      ? typeof inboundLogisticsFunctionalDetails?.isLightingWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isLightingWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Batterie',
    key: 'inboundLogisticsFunctionalDetails',
    render: ({ inboundLogisticsFunctionalDetails }) => renderBoolean(inboundLogisticsFunctionalDetails?.isBatteryWorking),
    isVisible: ({ machinery, inboundLogisticsFunctionalDetails }) => machinery
      ? typeof inboundLogisticsFunctionalDetails?.isBatteryWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isBatteryWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Warnlicht, Hupe',
    key: 'inboundLogisticsFunctionalDetails',
    render: ({ inboundLogisticsFunctionalDetails }) => renderBoolean(inboundLogisticsFunctionalDetails?.areWarningLightAndHornWorking),
    isVisible: ({ machinery, inboundLogisticsFunctionalDetails }) => machinery
      ? typeof inboundLogisticsFunctionalDetails?.areWarningLightAndHornWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'areWarningLightAndHornWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Sicherheitsabschaltung',
    key: 'inboundLogisticsFunctionalDetails',
    render: ({ inboundLogisticsFunctionalDetails }) => renderBoolean(inboundLogisticsFunctionalDetails?.isSafetyShutdownWorking),
    isVisible: ({ machinery, inboundLogisticsFunctionalDetails }) => machinery
      ? typeof inboundLogisticsFunctionalDetails?.isSafetyShutdownWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isSafetyShutdownWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Hydrauliksystem',
    key: 'inboundLogisticsFunctionalDetails',
    render: ({ inboundLogisticsFunctionalDetails }) => renderBoolean(inboundLogisticsFunctionalDetails?.isHydraulicSystemWorking),
    isVisible: ({ machinery, inboundLogisticsFunctionalDetails }) => machinery
      ? typeof inboundLogisticsFunctionalDetails?.isHydraulicSystemWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isHydraulicSystemWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'GPS in Ordnung',
    key: 'inboundLogisticsFunctionalDetails',
    render: ({ inboundLogisticsFunctionalDetails }) => renderBoolean(inboundLogisticsFunctionalDetails?.isGpsWorking),
    isVisible: ({ machinery, inboundLogisticsFunctionalDetails }) => machinery
      ? typeof inboundLogisticsFunctionalDetails?.isGpsWorking === 'boolean' && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isGpsWorking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Bemerkung',
    key: 'inboundLogisticsFunctionalDetails',
    render: ({ inboundLogisticsFunctionalDetails }) => inboundLogisticsFunctionalDetails?.note,
    isVisible: ({ machinery, inboundLogisticsFunctionalDetails }) => machinery
      ? Boolean(inboundLogisticsFunctionalDetails?.note) && useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'note', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Sichtprüfung',
    key: 'inboundLogisticsVisualDetails',
    isHeading: true,
  },
  {
    title: 'Gerät komplett',
    key: 'inboundLogisticsVisualDetails',
    render: ({ inboundLogisticsVisualDetails }) => renderBoolean(inboundLogisticsVisualDetails?.isMachineryComplete),
    isVisible: ({ machinery, inboundLogisticsVisualDetails }) => machinery
      ? typeof inboundLogisticsVisualDetails?.isMachineryComplete === 'boolean' && useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'isMachineryComplete', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Äußere Schäden',
    key: 'inboundLogisticsVisualDetails',
    render: ({ inboundLogisticsVisualDetails }) => renderBoolean(inboundLogisticsVisualDetails?.hasExternalDamage),
    isVisible: ({ machinery, inboundLogisticsVisualDetails }) => machinery
      ? typeof inboundLogisticsVisualDetails?.hasExternalDamage === 'boolean' && useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'hasExternalDamage', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Ölstand OK',
    key: 'inboundLogisticsVisualDetails',
    render: ({ inboundLogisticsVisualDetails }) => renderBoolean(inboundLogisticsVisualDetails?.isOilLevelOk),
    isVisible: ({ machinery, inboundLogisticsVisualDetails }) => machinery
      ? typeof inboundLogisticsVisualDetails?.isOilLevelOk === 'boolean' && useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'isOilLevelOk', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Kühlwasserstand OK',
    key: 'inboundLogisticsVisualDetails',
    render: ({ inboundLogisticsVisualDetails }) => renderBoolean(inboundLogisticsVisualDetails?.isCoolantLevelOk),
    isVisible: ({ machinery, inboundLogisticsVisualDetails }) => machinery
      ? typeof inboundLogisticsVisualDetails?.isCoolantLevelOk === 'boolean' && useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'isCoolantLevelOk', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Leckagen',
    key: 'inboundLogisticsVisualDetails',
    render: ({ inboundLogisticsVisualDetails }) => renderBoolean(inboundLogisticsVisualDetails?.isLeaking),
    isVisible: ({ machinery, inboundLogisticsVisualDetails }) => machinery
      ? typeof inboundLogisticsVisualDetails?.isLeaking === 'boolean' && useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'isLeaking', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Gerät gereinigt',
    key: 'inboundLogisticsVisualDetails',
    render: ({ inboundLogisticsVisualDetails }) => renderBoolean(inboundLogisticsVisualDetails?.isMachineryCleaned),
    isVisible: ({ machinery, inboundLogisticsVisualDetails }) => machinery
      ? typeof inboundLogisticsVisualDetails?.isMachineryCleaned === 'boolean' && useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'isMachineryCleaned', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Verschmutzung',
    key: 'inboundLogisticsVisualDetails',
    render: ({ inboundLogisticsVisualDetails }) => logisticsVisualDetailsPollutionToGerman[inboundLogisticsVisualDetails?.pollution as LogisticsVisualDetailsPollution],
    isVisible: ({ machinery, inboundLogisticsVisualDetails }) => machinery
      ? Boolean(inboundLogisticsVisualDetails?.pollution) && useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'pollution', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Masseband vorhanden',
    key: 'inboundLogisticsVisualDetails',
    render: ({ inboundLogisticsVisualDetails }) => renderBoolean(inboundLogisticsVisualDetails?.isTapeMeasureAvailable),
    isVisible: ({ machinery, inboundLogisticsVisualDetails }) => machinery
      ? typeof inboundLogisticsVisualDetails?.isTapeMeasureAvailable === 'boolean' && useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'isTapeMeasureAvailable', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Bemerkung',
    key: 'inboundLogisticsVisualDetails',
    render: ({ inboundLogisticsVisualDetails }) => inboundLogisticsVisualDetails?.note,
    isVisible: ({ machinery, inboundLogisticsVisualDetails }) => machinery
      ? Boolean(inboundLogisticsVisualDetails?.note) && useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'note', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Zustand',
    key: 'inboundLogisticsConditionDetails',
    isHeading: true,
  },
  {
    title: 'Reifen Luft / SE',
    key: 'inboundLogisticsConditionDetails',
    render: ({ inboundLogisticsConditionDetails }) => logisticsConditionRatingToGerman[inboundLogisticsConditionDetails?.tiresAirAndSE as LogisticsConditionRating],
    isVisible: ({ machinery, inboundLogisticsConditionDetails }) => machinery
      ? Boolean(inboundLogisticsConditionDetails?.tiresAirAndSE) && useShowFieldByFilters(logisticsConditionDetailsFieldsByMachineCategory, 'tiresAirAndSE', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Gabeln / Schaufeln',
    key: 'inboundLogisticsConditionDetails',
    render: ({ inboundLogisticsConditionDetails }) => logisticsConditionRatingToGerman[inboundLogisticsConditionDetails?.forkesAndShovels as LogisticsConditionRating],
    isVisible: ({ machinery, inboundLogisticsConditionDetails }) => machinery
      ? Boolean(inboundLogisticsConditionDetails?.forkesAndShovels) && useShowFieldByFilters(logisticsConditionDetailsFieldsByMachineCategory, 'forkesAndShovels', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Lackierung',
    key: 'inboundLogisticsConditionDetails',
    render: ({ inboundLogisticsConditionDetails }) => logisticsConditionRatingToGerman[inboundLogisticsConditionDetails?.painting as LogisticsConditionRating],
    isVisible: ({ machinery, inboundLogisticsConditionDetails }) => machinery
      ? Boolean(inboundLogisticsConditionDetails?.painting) && useShowFieldByFilters(logisticsConditionDetailsFieldsByMachineCategory, 'painting', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Fahrersitz',
    key: 'inboundLogisticsConditionDetails',
    render: ({ inboundLogisticsConditionDetails }) => logisticsConditionRatingToGerman[inboundLogisticsConditionDetails?.driverSeat as LogisticsConditionRating],
    isVisible: ({ machinery, inboundLogisticsConditionDetails }) => machinery
      ? Boolean(inboundLogisticsConditionDetails?.driverSeat) && useShowFieldByFilters(logisticsConditionDetailsFieldsByMachineCategory, 'driverSeat', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
  {
    title: 'Bemerkung',
    key: 'inboundLogisticsConditionDetails',
    render: ({ inboundLogisticsConditionDetails }) => inboundLogisticsConditionDetails?.note,
    isVisible: ({ machinery, inboundLogisticsConditionDetails }) => machinery
      ? Boolean(inboundLogisticsConditionDetails?.note) && useShowFieldByFilters(logisticsConditionDetailsFieldsByMachineCategory, 'note', machinery.category as MachineryCategories, machinery.drive.name as MachineryDrives)
      : false,
  },
]

const logisticsIssuanceAccessoryDetails: OneDataColumn<LogisticsTaskGetByCuidPositionToShip | LogisticsTaskItemSetMemberPosition>[] = [
  {
    title: 'Geräte Nr.',
    key: 'machineryAccessoryId',
    minWidth: 200,
  },
  {
    title: 'Gerät komplett',
    key: 'outboundLogisticsAccessoryDetails',
    render: ({ outboundLogisticsAccessoryDetails }) => renderBoolean(outboundLogisticsAccessoryDetails?.isMachineryAccessoryComplete),
    isVisible: ({ outboundLogisticsAccessoryDetails }) => typeof outboundLogisticsAccessoryDetails?.isMachineryAccessoryComplete === 'boolean',
  },
  {
    title: 'Äußere Schäden',
    key: 'outboundLogisticsAccessoryDetails',
    render: ({ outboundLogisticsAccessoryDetails }) => renderBoolean(outboundLogisticsAccessoryDetails?.hasExternalDamage),
    isVisible: ({ outboundLogisticsAccessoryDetails }) => typeof outboundLogisticsAccessoryDetails?.hasExternalDamage === 'boolean',
  },
  {
    title: 'Funktionsprüfung',
    key: 'outboundLogisticsAccessoryDetails',
    render: ({ outboundLogisticsAccessoryDetails }) => renderBoolean(outboundLogisticsAccessoryDetails?.isFunctioning),
    isVisible: ({ outboundLogisticsAccessoryDetails }) => typeof outboundLogisticsAccessoryDetails?.isFunctioning === 'boolean',
  },
  {
    title: 'Bemerkung',
    key: 'outboundLogisticsAccessoryDetails',
    render: ({ outboundLogisticsAccessoryDetails }) => outboundLogisticsAccessoryDetails?.note,
    isVisible: ({ outboundLogisticsAccessoryDetails }) => Boolean(outboundLogisticsAccessoryDetails?.note),
  },
]

const logisticsReturnAccessoryDetails: OneDataColumn<LogisticsTaskGetByCuidPositionToShip | LogisticsTaskItemSetMemberPosition>[] = [
  {
    title: 'Geräte Nr.',
    key: 'machineryAccessoryId',
    minWidth: 200,
  },
  {
    title: 'Gerät komplett',
    key: 'inboundLogisticsAccessoryDetails',
    render: ({ inboundLogisticsAccessoryDetails }) => renderBoolean(inboundLogisticsAccessoryDetails?.isMachineryAccessoryComplete),
    isVisible: ({ inboundLogisticsAccessoryDetails }) => typeof inboundLogisticsAccessoryDetails?.isMachineryAccessoryComplete === 'boolean',
  },
  {
    title: 'Äußere Schäden',
    key: 'inboundLogisticsAccessoryDetails',
    render: ({ inboundLogisticsAccessoryDetails }) => renderBoolean(inboundLogisticsAccessoryDetails?.hasExternalDamage),
    isVisible: ({ inboundLogisticsAccessoryDetails }) => typeof inboundLogisticsAccessoryDetails?.hasExternalDamage === 'boolean',
  },
  {
    title: 'Funktionsprüfung',
    key: 'inboundLogisticsAccessoryDetails',
    render: ({ inboundLogisticsAccessoryDetails }) => renderBoolean(inboundLogisticsAccessoryDetails?.isFunctioning),
    isVisible: ({ inboundLogisticsAccessoryDetails }) => typeof inboundLogisticsAccessoryDetails?.isFunctioning === 'boolean',
  },
  {
    title: 'Bemerkung',
    key: 'inboundLogisticsAccessoryDetails',
    render: ({ inboundLogisticsAccessoryDetails }) => inboundLogisticsAccessoryDetails?.note,
    isVisible: ({ inboundLogisticsAccessoryDetails }) => Boolean(inboundLogisticsAccessoryDetails?.note),
  },
]

const logisticsPlanningTaskOverview: OneDataColumn<DispatcherTaskForApp | ApiOfferGetById['logisticsTasks'][number]>[] = [
  {
    title: 'Typ',
    key: 'id',
    render: ({ offer }) => offer ? offerTypesToGerman[offer.type as OfferType] : 'Unbekannt',
  },
  {
    title: 'Lieferort',
    key: 'offer',
    render: ({ offer }) => offer?.deliveryLocation,
  },
  {
    title: 'Lagerort',
    key: 'id',
    render: task => getStorageLocationFromLogisticsTask(task as ApiLogisticsTaskGetById),
    isVisible: task => Boolean(getStorageLocationFromLogisticsTask(task as ApiLogisticsTaskGetById)),
  },
  {
    title: 'Liefer-Datum (Auftrag)',
    key: 'offer',
    render: ({ offer }) => offer?.deliveryAt ? useDateAsString(offer?.deliveryAt) : 'N/A',
  },
]

const deliveryChecklist: OneDataColumn<ApiLogisticsTaskGetById>[] = [
  {
    title: 'Standort der übergebenen Geräte',
    key: 'deliveryLocation',
    renderComponent: (task) => {
      const { deliveryLocation } = task

      if (!deliveryLocation) {
        return h('span', 'N/A')
      }

      const googleMapsLink = useGoogleMapsLink(deliveryLocation)

      const shareButton = h(NButton, { size: 'tiny', onClick: () => share({ url: googleMapsLink }) }, useRenderIcon({ name: 'material-symbols:ios-share' }))
      const mailButton = h(NButton, { size: 'tiny', onClick: () => navigateTo(getMailtoForLogisticsEmail(task, googleMapsLink), { external: true }) }, useRenderIcon({ name: 'material-symbols:mail-outline' }))
      const copyButton = h(NButton, { size: 'tiny', onClick: () => copy(googleMapsLink) }, useRenderIcon({ name: 'material-symbols:content-copy-outline' }))

      return h('div', { class: 'flex items-center gap-2' }, [
        h('a', { href: googleMapsLink, target: '_blank', class: 'text-blue-500 hover:underline' }, deliveryLocation),
        ...(isShareSupported.value ? [shareButton] : []),
        mailButton,
        ...(isCopySupported.value ? [copyButton] : []),
      ])
    },
    isVisible: ({ deliveryLocation }) => Boolean(deliveryLocation),
  },
  {
    title: 'Eingewiesene Person',
    key: 'deliveryPerson',
    render: ({ deliveryPerson }) => deliveryPerson ?? 'N/A',
    isVisible: ({ deliveryPerson }) => Boolean(deliveryPerson),
  },
  {
    title: 'Bemerkungen',
    key: 'deliveryNotes',
    render: ({ deliveryNotes }) => deliveryNotes ?? 'N/A',
    isVisible: ({ deliveryNotes }) => Boolean(deliveryNotes),
  },
]

const issuancePopupInformation: OneDataColumn<ApiOfferPositionGetById>[] = [
  {
    title: 'Auftrag',
    key: 'id',
    render: ({ relatedOffer }) => relatedOffer.id,
  },
  {
    title: 'Geräte Nr.',
    key: 'id',
    render: ({ machinery }) => machinery?.id,
    isVisible: ({ machinery }) => Boolean(machinery),
  },
  {
    title: 'Gerätetyp',
    key: 'id',
    render: ({ machinery }) => machinery?.type.name,
    isVisible: ({ machinery }) => Boolean(machinery),
  },
  {
    title: 'Hersteller',
    key: 'id',
    render: ({ machinery }) => machinery?.producerCompanyName,
    isVisible: ({ machinery }) => Boolean(machinery),
  },
  {
    title: 'Letztes UVV-Datum',
    key: 'id',
    render: ({ machinery }) => machinery?.lastUVVDate ? useDateAsString(machinery.lastUVVDate, 'dd.MM.yy') : 'N/A',
    isVisible: ({ machinery }) => Boolean(machinery),
  },
  {
    title: 'Kunde',
    key: 'id',
    render: ({ relatedOffer }) => relatedOffer.customer.name,
  },
  {
    title: 'Gerät',
    key: 'id',
    render: ({ machineryAccessory, machineryAccessoryCategory }) => machineryAccessory ? `${machineryAccessory.id}, ${machineryAccessoryCategoryToGerman[machineryAccessory.category as MachineryAccessoryCategory]}, ${machineryAccessory.producerCompanyName}` : machineryAccessoryCategoryToGerman[machineryAccessoryCategory as MachineryAccessoryCategory],
    isVisible: ({ type }) => ['machineryAccessory', 'machineryAccessoryCategory'].includes(type),
  },
]

const itemSetColumnsPublic: OneDataColumn<ApiItemSetPublicGetById | ApiItemSetGetById>[] = [
  {
    title: 'Id',
    key: 'id',
  },
  {
    title: 'Title',
    key: 'title',
    render: ({ title }) => title,
  },
  {
    title: 'Beschreibung',
    key: 'description',
    render: ({ description }) => description ?? ' ',
  },
]

const itemSetColumns: OneDataColumn<ApiItemSetGetById>[] = [
  ...itemSetColumnsPublic,
  {
    title: 'Lagerplatz',
    key: 'storageLocation',
    render: ({ storageLocation }) => storageLocation ?? 'N/A',
  },
  {
    title: 'Hersteller',
    key: 'producerCompanyName',
    render: ({ producerCompanyName }) => producerCompanyName ?? 'N/A',
  },
  {
    title: 'Typ',
    key: 'typeName',
    render: ({ typeName }) => typeName ?? 'N/A',
  },
  {
    title: 'Tragkraft (kg)',
    key: 'liftingWeightInKilograms',
    render: ({ liftingWeightInKilograms }) => liftingWeightInKilograms?.toLocaleString('de-DE'),
  },
  {
    title: 'Gewicht (kg)',
    key: 'weightInKilograms',
    render: ({ weightInKilograms }) => weightInKilograms?.toLocaleString('de-DE'),
  },
  {
    title: 'Transport-Länge (mm)',
    key: 'transportLengthInMillimeters',
    render: ({ transportLengthInMillimeters }) => transportLengthInMillimeters?.toLocaleString('de-DE'),
  },
  {
    title: 'Transport-Breite (mm)',
    key: 'transportWidthInKilograms',
    render: ({ transportWidthInKilograms }) => transportWidthInKilograms?.toLocaleString('de-DE'),
  },
  {
    title: 'Transport-Höhe (mm)',
    key: 'transportHeightInKilograms',
    render: ({ transportHeightInKilograms }) => transportHeightInKilograms?.toLocaleString('de-DE'),
  },
  {
    title: 'Erstellt am',
    key: 'createdAt',
    render: row => useDateAsString(row.createdAt),
  },
  {
    title: 'Erstellt von',
    key: 'createdBy',
    render: row => row.createdBy.name,
  },
]

const logisticsCompletedInformation: OneDataColumn<ApiLogisticsTaskGetById>[] = [
  {
    title: 'Abgeschlossen am',
    key: 'completedAt',
    render: ({ completedAt }) => completedAt ? useDateAsString(completedAt) : ' ',
  },
  {
    title: 'Abgeschlossen von',
    key: 'completedBy',
    render: ({ completedBy }) => completedBy?.name ?? ' ',
  },
]

const lastUVVDate: OneDataColumn<ApiMachineryGetById | ApiMachineryAccessoryGetById>[] = [
  {
    title: 'Letztes UVV-Datum',
    key: 'lastUVVDate',
    render: ({ lastUVVDate }) => lastUVVDate ? useDateAsString(lastUVVDate, 'dd.MM.yyyy') : 'N/A',
  },
]

const machineryInspectionDate: OneDataColumn<ApiMachineryGetById>[] = [
  {
    title: 'Nächste TÜV-Prüfung fallig am',
    key: 'technicalInspectionDate',
    render: ({ technicalInspectionDate }) => technicalInspectionDate ? useDateAsString(technicalInspectionDate, 'dd.MM.yyyy') : 'N/A',
  },
  {
    title: 'Nächste Sicherheitsprüfung fällig am',
    key: 'securityInspectionDate',
    render: ({ securityInspectionDate }) => securityInspectionDate ? useDateAsString(securityInspectionDate, 'dd.MM.yyyy') : 'N/A',
  },
  {
    title: 'Nächste Tacho Prüfung fällig am',
    key: 'speedometerInspectionDate',
    isVisible: ({ speedometerInspectionDate }) => !!speedometerInspectionDate,
    render: ({ speedometerInspectionDate }) => speedometerInspectionDate && useDateAsString(speedometerInspectionDate, 'dd.MM.yyyy'),
  },
  {
    title: 'Sondergenehmigung fällig am',
    key: 'specialPermissions',
    isVisible: ({ specialPermissions }) => specialPermissions.length > 0,
    render: ({ specialPermissions }) => specialPermissions
      .map(sp => `${sp.title} (${useDateAsString(sp.date, 'dd.MM.yyyy')})`)
      .join(', '),
  },
]

type InboundLogisticsTask = ApiInboundLogisticsTaskById | ApiInboundLogisticsTaskForDriver
type StoragePositionSet = InboundLogisticsTask['storagePositionSets'][number]
const storagePositionSetColumn: OneDataColumn<StoragePositionSet>[] = [
  {
    title: 'Länge (mm)',
    key: 'announcedLengthInMm',
  },
  {
    title: 'Breite (mm)',
    key: 'announcedWidthInMm',
  },
  {
    title: 'Höhe (mm)',
    key: 'announcedHeightInMm',
  },
  {
    title: 'Bruttogewicht (kg)',
    key: 'announcedTotalGrossWeightIncludingLoadCarrierInKg',
  },
]

type StoragePosition = StoragePositionSet['storagePositions'][number]
const storagePositionColumn: OneDataColumn<StoragePosition>[] = [
  {
    title: 'Bezeichnung',
    key: 'storageProduct',
    render: ({ storageProduct }) => storageProduct.name,
  },
  {
    title: 'Menge',
    key: 'quantity',
  },
  {
    title: 'Waschen',
    key: 'needsToBeWashedOnArrival',
    render: ({ needsToBeWashedOnArrival }) => needsToBeWashedOnArrival ? 'Ja' : 'Nein',
  },
]

const configs = {
  ...oneDataColumnConfigPublicMachinery,
  machineryPrice,
  machinerySalePrice,
  machineryRelease,
  machineryInspectionDate,
  storageLocationColumn,
  receptionMachineryVehicle,
  receptionMachineryPlatform,
  receptionMachineryMast,
  receptionMachineryDimension,
  receptionMachineryTires,
  receptionMachineryMotor,
  receptionMachineryBattery,
  receptionMachinerySteeringType,
  receptionMachineryExtraInfo,
  machineryAccessoryBaseInformation,
  machineryAccessoryPriceInformation,
  machineryAccessoryInspectionDate,
  machineryAccessoryPublicCreationInformation,
  logisticsTaskGeneralInformation,
  logisticsTaskGeneralInformationForDriver,
  logisticsTaskPositionsOverview,
  logisticsIssuanceAndMachineryDetails,
  logisticsIssuanceAccessoryDetails,
  logisticsPlanningTaskOverview,
  deliveryChecklist,
  issuancePopupInformation,
  logisticsReturnAndMachineryDetails,
  logisticsReturnAccessoryDetails,
  itemSetColumnsPublic,
  itemSetColumns,
  logisticsCompletedInformation,
  lastUVVDate,
  storagePositionSetColumn,
  storagePositionColumn,
}
export default () => configs
